
import React, { useEffect, useState } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import './profile.scss';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getCustomerDetails } from 'app/entities/customer/customer.reducer';
import { getCompletedAccountType, getPendingEntity, getPendingTask, ibkrportal } from './profile.reducer';
import { setAccountLastLevel, getEntity, getSignature, getIbkr } from 'app/entities/accounts/accounts.reducer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { saveInitialPath } from '../account/register/register.reducer';

interface Customer {
  account_id: string;
  primary_signed_date: string;
  primary_sign: string;
  primary_customer_id: string;
  joint_customer_id: string | null;
  joint_signed_date: string | null;
  joint_sign: string | null;
}

interface DataObject {
  [x: string]: any;
  smart_reserve_portfolio: Customer;
  statement_of_suitability: Customer;
  w8_ben: Customer;
}

const ClientPortal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const signatureData = useAppSelector(state => state.accounts.signatureData);

  const accountId = useAppSelector(state => state.accounts?.entity?.accountId)
    || signatureData?.statement_of_suitability?.account_id;
  const [pendingDocumentation, setPendingDocumentation] = useState(true);
  const [PendingAccounts, setPendingAccounts] = useState<any>([]);
  const [pendingAccountId, setPendingAccountId] = useState('');

  const [PendingTasks, setPendingTasks] = useState([]);
  const [accountNumber, setAccountNumber] = useState(null)

  const accountsEntity = useAppSelector(state => state.accounts.entity);
  const [secondaryCustomerId, setSecondaryCustomerId] = useState(null)
  const [holderType, setHolderType] = useState();
  const [data, setData] = useState([]);
  const [ibkrData, setIbkrData] = useState(null);
  const [masterType, setMasterType] = useState();


  useEffect(() => {
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {
      const tokenParts = accessToken?.split('.');
      if (tokenParts?.length === 3) {
        try {
          const payload = JSON.parse(atob(tokenParts[1]));
          if (payload?.sub) {
            const { sub } = payload;
            setCustomerId(sub)
          }
        } catch (error) { /* empty */ }
      }

      // navigate('/accounts');
      if (window.location.pathname === '/accounts') {
        navigate('/accounts')
      } else {
        if (window.location.pathname === '/company') {
          navigate('/company')
        } else if (window.location.pathname === '/user-profile') {
          navigate('/user-profile')
        } else if (window.location.pathname === '/admin-profile') {
          navigate('/admin-profile')
        }
      }
    } else {
      dispatch(saveInitialPath(window.location.pathname))
      navigate('/signin');

    }
  }, []);

  const [completedAccountType, setCompletedAccountType] = useState()
  const [completedMasterType, setCompletedMasterType] = useState()
  useEffect(() => {
    dispatch(getCompletedAccountType({}))
      .then((response: any) => {

        setCompletedAccountType(response?.payload?.data?.accountHolderType)
        setCompletedMasterType(response?.payload?.data?.accountMasterType)

      }).catch(error => {
        console.error('Error:', error);
      });
  }, []);

  useEffect(() => {
    dispatch(getPendingTask({}))
      .then((response: any) => {

        setData(response?.payload?.data?.signature_pending)
        const pendingAccountsList = response?.payload?.data?.pending_accounts;
        const signaturePendingList = response?.payload?.data?.signature_pending
        const secondaryId = signaturePendingList?.[0]?.secondary_customer_id;
        setSecondaryCustomerId(secondaryId)
        setMasterType(signaturePendingList?.[0]?.accountMasterType)

        if (signaturePendingList) {
          setAccountNumber(signaturePendingList?.[0]?.accountId)
        }

        if (pendingAccountsList && pendingAccountsList.length > 0 || signaturePendingList && signaturePendingList.length > 0) {
          let firstAccount: any = pendingAccountsList.filter(
            (accountobj: { accountId: string }) => accountobj.accountId === sessionStorage.getItem('accountid'),
          );
          const signaturePendingAccount: any = response.payload.data.signature_pending.filter(
            (accountobj: { accountId: string }) => accountobj.accountId === sessionStorage.getItem('accountid'),
          );

          let level = '';
          let firstAccountId = '';
          if (pendingAccountsList.length > 0) {
            level = pendingAccountsList[0]?.last_level;
            firstAccountId = pendingAccountsList[0]?.accountId;
          } else {
            if (signaturePendingAccount.length > 0) {
              level = signaturePendingAccount[0]?.last_level;
              firstAccountId = signaturePendingAccount[0]?.accountId;
              firstAccount = signaturePendingAccount;
            }
          }
          dispatch(setAccountLastLevel(level));
          setPendingTasks(pendingAccountsList);

          const customerId = accountsEntity.customerId;

          const filteredHolderData = pendingAccountsList
            .filter((type: any) => {
              return type.customerId === customerId;
            })
            .map((type: any) => type.accountHolderType);

          setHolderType(filteredHolderData);

          if (firstAccountId !== '') {
            dispatch(getEntity(firstAccountId));
          }
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  const customerDetails = useAppSelector(state => state.customer.entities);
  const { last_level } = useAppSelector(state => state.accounts.entity);
  const [name, setName] = useState(customerDetails?.firstName || '');
  const [lastName, setLastName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (customerDetails) {
      setName(customerDetails?.firstName || '');
      setLastName(customerDetails?.lastName || '');
      setCustomerId(customerDetails?.customerId || '');
      setEmail(customerDetails?.email || '');
      setPhone(customerDetails?.phone || '');
    }
  }, [customerDetails]);

  useEffect(() => {
    dispatch(getCustomerDetails());
  }, []);

  useEffect(() => {
    if (accountId) {
      dispatch(getSignature(accountId));
    }
  }, [accountId, dispatch])

  useEffect(() => {
    dispatch(getIbkr()).then((response: any) => {
      if (response?.payload) {
        setIbkrData(response?.payload);
      }
    });
  }, [dispatch]);

  const [AccountType, setAccountType] = useState('');
  useEffect(() => {

    setAccountType(accountsEntity?.accountHolderType);
  }, [accountsEntity]);


  const handleIBKRPortalLinkClick = async () => {
    try {
      const resultAction = await dispatch(ibkrportal());

      if (resultAction && resultAction.payload) {
        const portalUrl = resultAction.payload;
        window.open(portalUrl, '_blank');
      } else {
        console.error('IBKR Portal URL not found');
      }
    } catch (error) {
      console.error('Error fetching IBKR Portal URL:', error);
    }
  };
  useEffect(() => {
    dispatch(getPendingEntity()).then((response: any) => {
      
        setPendingAccounts(response?.payload?.data);
    })
  }, [dispatch]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid">
      <Row className='justify-content-center'>
      </Row>
      <Row className="pt-5 justify-content-center">
        <main className="col-11  ">
          <div className="card bg-white mt-2 mt-md-0 ">
            <div className="card-body " >
              <div className='text-end '>
              <Link to="/change-password " >
             
              <button  className="pt-2 button-investment mt-2" style={{ fontSize: 12 ,position:"relative",top:"35px"}}>
                Change Password
              </button>
            </Link>


              </div>
              <div className="user-profile-text1">
                <div className="py-2 pending-task new-profile-color" style={{fontSize:"20px"}}>Your Client Portal	</div>
                <div className="user-profile-text1">
                  <div className='d-flex justify-content-between align-item-center  new-profile-color'>
                    <div className="">
                        <div style={{ lineHeight: '2.5', fontSize: 'small', fontWeight: 'bold' }}>
                          <span style={{ display: 'inline-block', width: '180px' }}>Customer Name:</span>
                          <span style={{ lineHeight: '2.5', fontWeight: '300' }} className="text-black pb-2">
                            {`${PendingAccounts?.accountHolderName || customerDetails?.firstName} ${PendingAccounts?.accountHolderSurname || customerDetails?.lastName}`}
                          </span>
                        </div>
                     
                    </div>

                  </div>
                </div>
                <Row>
                  <Col md="6">
                    <table className="table custom-table-td">
                      <thead className='new-form-table-row-two'>
                        <tr>
                          <th>SI No</th>
                          <th>Starlight Account Type</th>
                          <th>Created At</th>
                        </tr>
                      </thead>
                      <tbody className='table-td new-form-table-row-two'>
                        {PendingAccounts?.map((account, index) => (
                          <tr key={account.id}>
                            <td>{index + 1}</td>
                            <td className="table-td">
                            <Link 
                              to={`/user-profile/${account.id}`} 
                              onClick={() => {
                                setPendingAccountId(account?.accountHolderType);
                                sessionStorage.setItem('accountId', account.id); 
                              }} 
                              className="text-primary"
                            >
                              {`Starlight Smart Reserve ${account?.accountHolderType}`}
                            </Link>
                            </td>
                            <td>{account?.updated_at}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </div>
              {/* )} */}


            </div>
          </div>
        </main >
      </Row >
    </div >
  );
};

export default ClientPortal;
