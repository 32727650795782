import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import './formDoc.scss';
import { useReactToPrint } from 'react-to-print';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getCountryList, getSignature } from './accounts.reducer';
import { useLocation, useParams } from 'react-router';
import { getCustomerDetails } from '../customer/customer.reducer';
import { getPendingTask } from 'app/modules/profile/profile.reducer';
import { getAdminAccounts, getCustomerDetailsList} from '../admin-profile/admin.reduce';

const role=sessionStorage.getItem("ROLE");

export const FormDoc = ({
  docConfirmation3,
  handleCheckboxChangeThree,
  handleUpdateNextStep,
  FormData2,
  email,
  setEmail,
  name,
  accountHolderType,
  w8benPrimary_sign,
  setw8benPrimary_sign,
  setDifferentData,
  setMailing_address,
  mailing_address,
  differentData,
  w8benSecondarySign,
  setw8benSecondarySign,

}) => {
  const dispatch = useAppDispatch();
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);

  const [dateOfBirth, setDateOfBirth] = useState('');

  // const [mailing_address, setMailing_address] = useState({
  //   street: '',
  //   city: '',
  //   state: '',
  //   zip_code: '',
  //   country: '',
  // })

  // const [differentData, setDifferentData] = useState({
  //   ssn: '',
  //   ftn: false,
  //   reference_number: '',
  //   beneficial_owner_residence: '',
  //   article_and_paragraph: '',
  //   rate_of_with_holding: 0.0,
  //   type_of_income: '',
  //   additional_condition: '',
  //   eligibility_explanation: '',
  // });

  const handleInputChange = (e) => {
    const { name, value, } = e.target;
    setDifferentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCombinedInputChange = (e, field) => {
    const value = e.target.value;

    setMailing_address((prevAddress) => ({
      ...prevAddress,
      [field]: value,
    }));
  };




  const formatDate = (dob) => {
    return new Date(dob).toLocaleDateString('en-US', customerDetails?.dob || customerDOB);
  };

  const location = useLocation();
  // const dispatch = useAppDispatch();
  const { level, _signatureDataPending } = location.state || {};
  const [signatureDate, setSignatureDate] = useState('');
  const [customerIdNum,setCustomerIdNum]=useState(localStorage.getItem('customerId') ||'')
  const [customerDOB,setCustomerDOB]=useState('')
  
  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0]; // Get YYYY-MM-DD format
    setSignatureDate(formattedDate);
  }, []);

  const { accountId } = useParams();


  const [applicationData, setApplicationData] = useState<any>({});
  const [newData, setNewData] = useState<any>({});

  useEffect(() => {
    if (accountId) {

      dispatch(getAdminAccounts({ accountId }))
        .then((response: any) => {
       
          if (response?.payload?.data) {
            setApplicationData(response?.payload?.data?.account_signature_response);
            setNewData(response?.payload?.data);
          }       
           
        })
        .catch((error) => {
        });
    }
  }, [accountId]);
 
  

  const pageStyle = `

    @media print {
      body {
        font-size: 13px;
      }

      .no-print {
        display: none;
      }

      .new-form-bold-text,
      .new-form-italic-text,
      .new-form-underline-text {
        font-size: 13px;
        font-weight: bold;
        color: black;
      }

      .new-form-heading-text {
        font-weight: bold;
        font-size: 25px;
        color: black;
      }

      .new-form-light-text {
        font-weight: 300;
        font-size: 13px;
        color: black;
      }

      .new-form-very-light-text {
        font-weight: 300;
        font-size: 12px;
        color: black;
      }

      .new-form-italic-text {
        font-style: italic;
      }

      .bordered-table {
        width: 100%;
        border-collapse: collapse;
      }
      .bordered-table th,
      .bordered-table td {
        border: 1px solid black;
        padding: 8px;
        font-weight: 300;
        font-size: 13px;
        color: black;
      }
      .bordered-table th {
        background-color: #f2f2f2;
        font-weight: 300;
        font-size: 13px;
        color: black;
      }
      .new-form-table-row-one {
        background: #607980;
        color: black;
        font-weight: 300;
        font-size: 13px;
      }
      .new-form-table-row-two {
        background-color: #d5d8da;
        color: black;
        font-weight: 300;
        font-size: 13px;
      }
      .textfield-style {
        padding: 0.5rem 0.75rem;
        border: 1px solid #f1eded;
        border-radius: 0.25rem;
        background-color: #fff;
        font-size: 14px;
        color: #333;
        box-shadow: 0px 0.5px #c8c6c6;
      }
      .form-three-border {
        border-right: 1px solid black;
      }

      .form-row-border {
        border-bottom: 1px solid black;
      }

      .form-three-head {
        align-content: center;
      }
      .small-text {
        font-size: small;
      }
      .small-text-bold {
        font-size: small;
        font-weight: bolder;
      }
      .instruction-container-main {
        color: #000000;
        font-weight: 400;
      }
      .instruction-container-top-text {
        display: flex;
        justify-content: space-between;
        font-size: small;
        font-weight: bolder;
      }
      .instructions-text-container {
        font-size: small;
      }
      .instruction-container-text {
        display: flex;
        justify-content: space-between;
        font-size: small;
        color: #000000;
      }
      .instruction-container {
        border: none;
        border-bottom: 1px black solid;
      }
      .instruction-container-note-box {
        font-size: small;
      }
      .instruction-container-note-box-head {
        font-size: small;
        font-weight: bold;
      }
    }

  `;
  const [customerId, setCustomerId] = useState()
  useEffect(() => {
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {
      const tokenParts = accessToken.split('.');
      if (tokenParts?.length === 3) {
        try {
          const payload = JSON.parse(atob(tokenParts[1]));

          if (payload?.sub) {
            const { sub } = payload;
            setCustomerId(sub)
          }
        } catch (error) {
          console.error("Failed to parse JWT payload", error);
        }
      }
    }
  }, []);
  
 

  
  useEffect(() => {
    if(role ==='ROLE_ADMIN'){

       dispatch(getCustomerDetailsList(customerIdNum))
         .then((response: any) => {
           if(response?.payload?.data?.dob){
             setCustomerDOB(response?.payload?.data?.dob);
           }
         
         })
         .catch((error) => {
           console.error('Error fetching customer details:', error);
         });
         }
    
 }, []);



  const findCountryNameByKey = (key: string | null | undefined): string => {
    const country: any = countryList.find((obj: any) => obj.name === key);


    return country ? country.name : '';
  };
  const [countryList, setCountryList] = useState<string[]>([]);
  useEffect(() => {


    dispatch(getCountryList()).then((res) => {

      if (res.payload) {
        const countryData = res.payload.map((item: any) => item);
        setCountryList(countryData);
      }
    });
  }, [dispatch]);

  const handleCountryChange = (e) => {
    const { value } = e.target;
    setMailing_address((prevAddress) => ({
      ...prevAddress,
      country: value,
    }));
  };

  const [secondaryId, setSecondaryId] = useState()
  useEffect(() => {
    dispatch(getPendingTask({}))
      .then((response: any) => {
        const signaturePending = response?.payload?.data?.signature_pending;

        if (signaturePending) {
          signaturePending.forEach((item) => {
            setSecondaryId(item.secondary_customer_id);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [dispatch]);


  const currentDate = new Date().toLocaleDateString('en-US');
  const [day, month, year] = currentDate.split('/');

  const formattedDate = `${month}/${day}/${year.slice(-2)}`;
  const accountsEntity = useAppSelector(state => state?.accounts?.entity.accountId);

  const customerDetails = useAppSelector(state => state?.customer?.entities);
  
  const [accountHolderEmail, setAccountHolderEmail] = useState('')


  useEffect(() => {
    if (role === "ROLE_USER"){
    dispatch(getCustomerDetails());
    }
  }, [dispatch]);

  const signatureData = useAppSelector(state => state?.accounts?.signatureData);

  useEffect(() => {
    if (accountsEntity) {
      dispatch(getSignature(accountsEntity));
    }
  }, [accountsEntity, dispatch]);
  useEffect(() => {
    if (signatureData?.w8_ben && mainAccountsEntity?.accountHolderType !== 'Individual') {
      setw8benPrimary_sign(signatureData?.w8_ben?.primary_sign);
    }
  }, [signatureData]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'W-8BEN',
    onBeforePrint: () => console.log('before printing...'),
    onAfterPrint: () => console.log('after printing...'),
    pageStyle,
    removeAfterPrint: true,
  });

  const componentRef = useRef();
  return (
    <div className="form-container  ps-5 pt-5  " ref={componentRef}>
      <div className="row form-row-border">
        <div className="col-3 form-three-border">
          <div className="new-form-light-text">
            {' '}
            Form <span className="heading-text-left">W-8BEN</span>
          </div>

          <div className="new-form-light-text"> (Rev. October 2021)</div>
          <div className="new-form-light-text"> Department of the Treasury</div>
          <div className="new-form-light-text"> Internal Revenue Service </div>
        </div>
        <div className="col-6 text-center form-three-border">
          <div className="top-middle-container-heading-text  ">
            Certificate of Foreign Status of Beneficial Owner for United <br />
            States Tax Withholding and Reporting (Individuals)
          </div>
          <div className="arrow-icon-container">
            <div className="arrow-icon">
              {' '}
              &#x25B6; <span className="small-text-bold ">For use by individuals. Entities must use Form W-8BEN-E.</span>
            </div>
          </div>
          <div className="arrow-icon-container">
            <div className="arrow-icon">
              {' '}
              &#x25B6; <span className="small-text-bold "> Go to www.irs.gov/FormW8BEN for instructions and the latest information. </span>
            </div>
          </div>
          <div className="arrow-icon-container">
            <div className="arrow-icon">
              {' '}
              &#x25B6; <span className="small-text-bold "> Give this form to the withholding agent or payer. Do not send to the IRS</span>
            </div>
          </div>
        </div>
        <div className="col-3 form-three-head">
          <div className=" new-form-light-text">OMB No. 1545-1621</div>
        </div>
      </div>
      <div className="instruction-container-main">
        <div className="instruction-container">
          <div className="instruction-container-top-text">
            <label>Do NOT use this form if:</label>
            <label>Instead, use Form: </label>
          </div>
          <div className="instructions-text-container">
            <div className="instruction-container-text">
              <label className="text-label">
                <li className="text-item">You are NOT an individual</li>
              </label>
              <label className="text-label">W-8BEN-E</label>
            </div>
            <div className="instruction-container-text">
              <label className="text-label">
                <li className="text-item">You are a U.S. citizen or other U.S. person, including a resident alien individual.</li>
              </label>
              <label className="text-label">W-9</label>
            </div>
            <div className="instruction-container-text">
              <label className="text-label">
                <li className="text-item">You are a beneficial owner claiming that income is effectively connected with the conduct of trade or business within the United States (other than personal services)</li>
              </label>
              <label className="text-label">W-8ECI</label>
            </div>
            <div className="instruction-container-text">
              <label className="text-label">
                <li className="text-item">You are a beneficial owner who is receiving compensation for personal services performed in the United States</li>
              </label>
              <label className="text-label">8233 or W-4</label>
            </div>
            <div className="instruction-container-text">
              <label className="text-label">
                <li className="text-item">You are a person acting as an intermediary</li>
              </label>
              <label className="text-label">W-8IMY</label>
            </div>
          </div>

        </div>
        <div className="instruction-container-note-box">
          <label className="instruction-container-note-box-head">Note:</label>
          <label className="ps-2">
            If you are resident in a FATCA partner jurisdiction (that is, a Model 1 IGA jurisdiction with reciprocity), certain tax account
            information may be provided to your jurisdiction of residence.
          </label>
        </div>
      </div>
      <div className="part1-container">
        <div className="part1-head-text-box d-flex">
          <div className="part1-text-left-box-black">
            <label>Part I </label>
          </div>
          <label className="part1-head-box-text-normal">
            <label className="ps-2 part1-head-box-text-header">Identification of Beneficial Owner</label> (see instructions)
          </label>
        </div>
        <div className="form-input-line-1 d-flex col-md-12">
          <div className="form-input-line-box-left col-md-7">
            <label className="form-input-line-box-left-label pe-5 ">
              <strong>
                <label className="ps-3 pe-3">1</label>
              </strong>
              Name of individual who is the beneficial owner
            </label>
            <Input className="form-box-left-input"
              value={
                mainAccountsEntity?.accountHolderName && mainAccountsEntity?.accountHolderSurname
                  ? `${mainAccountsEntity.accountHolderName} ${mainAccountsEntity.accountHolderSurname}`
                  : `${newData?.accountHolderName || ''} ${newData?.accountHolderSurname || ''}`
              } />
          </div>
          <div className="form-input-line-box-right">
            <label className="form-input-line-box-right-label ">
              <strong>
                <label className="ps-3 pe-3">2</label>
              </strong>
              Country of citizenship
            </label>
            <Input className="form-box-right-input" value={mainAccountsEntity?.citizenship || newData?.citizenship} />
          </div>
        </div>
        <div className="form-input-line-2">
          <label className="form-input-line-box-label-single ">
            <strong>
              <label className="ps-3 pe-3">3</label>
            </strong>
            Permanent residence address (street, apt. or suite no., or rural route)
            <strong>. Do not use a P.O. box or in-care-of address.</strong>
          </label>
          <Input
            className="form-box-single-input"
            value={
              (mainAccountsEntity?.addressLine1 ? mainAccountsEntity.addressLine1 + ", " : "") +
              (mainAccountsEntity?.addressLine2 ? mainAccountsEntity.addressLine2 : "") +
              (newData?.addressLine1 ? newData.addressLine1 + ", " : "") +
              (newData?.addressLine2 ? newData.addressLine2 : "")
            }
          />

        </div>
        <div className="form-input-line-3 d-flex col-md-12">
          <div className="form-input-line-box-left col-md-8">
            <label className="form-input-line-box-left-label ps-3 pe-5">
              City or town, state or province. Include Post Code where appropriate.
            </label>
            <Input
              className="form-box-left-input"
              value={
                (mainAccountsEntity?.townOrCity ? mainAccountsEntity?.townOrCity + ", " : "") +
                (mainAccountsEntity?.state_or_province ? mainAccountsEntity?.state_or_province + ", " : "") +
                (mainAccountsEntity?.postcode ? mainAccountsEntity?.postcode : "") +
                (newData?.townOrCity ? newData?.townOrCity + ", " : "") +
                (newData?.state_or_province ? newData?.state_or_province + ", " : "") +
                (newData?.postcode ? newData?.postcode : "")
              }
            />
          </div>
          <div className="form-input-line-box-right">
            <label className="form-input-line-box-right-label ps-3  ">Country</label>
            <Input
              className="form-box-right-input"
              value={(mainAccountsEntity?.country ? mainAccountsEntity?.country : "" ) +( newData?.country ? newData?.country : '') }
            />  
          </div>
        </div>
        <div className="form-input-line-4">
          <label className="form-input-line-box-label-single ">
            <strong>
              <label className="ps-3 pe-3">4</label>
            </strong>
            Mailing address (if different from above)
          </label>
          <Input className="form-box-single-input"
            value={accountHolderEmail}
            onChange={e => setAccountHolderEmail(e.target.value)}

          />
        </div>
        <div className="form-input-line-5 d-flex col-md-12">
          <div className="form-input-line-box-left col-md-8">
            <label className="form-input-line-box-left-label ps-3 pe-5">
              City or town, state or province. Include Post Code where appropriate.
            </label>
            <Input
              className="form-box-left-input"
              value={mailing_address?.city || newData?.account_signature_response?.w8_ben?.mailing_address?.city}
              onChange={(e) => handleCombinedInputChange(e, 'city')}
              placeholder="City or town"
            />

            <Input
              className="form-box-left-input mt-1"
              value={mailing_address?.state || newData?.account_signature_response?.w8_ben?.mailing_address?.state}
              onChange={(e) => handleCombinedInputChange(e, 'state')}
              placeholder="State or Province"
            />

            <Input
              className="form-box-left-input mt-1"
              value={mailing_address?.postal_code || newData?.account_signature_response?.w8_ben?.mailing_address?.postal_code}
              onChange={(e) => handleCombinedInputChange(e, 'zip_code')}
              placeholder="Post Code"
            />
          </div>
          <div className="form-input-line-box-right">
            <label className="form-input-line-box-right-label ps-3">Country</label>
            <Input
              className="form-box-left-input mt-1"
              value={mailing_address?.country || newData?.account_signature_response?.w8_ben?.mailing_address?.country}
              onChange={(e) => handleCombinedInputChange(e, 'country')}
              placeholder="Country"
            />
          </div>

        </div>
        <div className="form-input-line-6">
          <label className="form-input-line-box-label-single ">
            <strong>
              <label className="ps-3 pe-3">5</label>
            </strong>
            U.S. taxpayer identification number (SSN or ITIN), if required (see instructions)
          </label>
          <Input className="form-box-single-input" name="ssn" value={differentData?.ssn || newData?.account_signature_response?.
w8_ben?.ssn} onChange={handleInputChange} />
        </div>
        <div className="form-input-line-1 d-flex col-md-12">
          <div className="form-input-line-box-left col-md-6">
            <label className="form-input-line-box-left-label pe-5">
              <strong>
                <label className="ps-3 pe-3">6a</label>
              </strong>
              Foreign tax identifying number (see instructions)
            </label>
            <Input className="form-box-left-input"
              value={mainAccountsEntity?.ppsNumber || newData?.ppsNumber
              }


            // onChange={e => setForeignTaxId(e.target.value)}

            />
          </div>
          <div className="form-input-line-box-right col-md-6">
            <span className="form-input-line-box-right-label d-flex">
              <span className="pe-3 ps-3">
                {' '}
                <strong>6b</strong>
              </span>
              <span className="d-flex form-input-line-box-right-label-isftinLegallyRequired ">
                Check if FTIN not legally required . . . . . . . . . . . . .
                <Input
                  type="checkbox"
                  name="ftn"
                  className="form-input-line-box-right-label-checkbox pe-2"
                  checked={differentData?.ftn || newData?.account_signature_response?.
                    w8_ben?.ftn}
                  onChange={handleInputChange}
                />
              </span>
            </span>
            {/* <Input className="form-box-right-input" value={ftinNotRequired} onChange={e => setFtinNotRequired(e.target.value)} /> */}
          </div>
        </div>
        <div className="form-input-line-7 d-flex col-md-12">
          <div className="form-input-line-box-left col-md-6">
            <label className="form-input-line-box-left-label pe-5">
              <strong>
                <label className="ps-3 pe-3">7</label>
              </strong>
              Reference number(s) (see instructions)
            </label>
            <Input className="form-box-left-input" name="reference_number" value={differentData?.reference_number || newData?.account_signature_response?.
                    w8_ben?.reference_number} onChange={handleInputChange} />
          </div>
          <div className="form-input-line-box-right col-md-6">
            <label className="form-input-line-box-right-label ">
              <strong>
                <label className="ps-3 pe-3">8</label>
              </strong>
              Date of birth (MM-DD-YYYY) (see instructions)
            </label>
            <Input className="form-box-right-input" 
            // value={customerDOB ? formatDate(customerDOB) : ''} 
            value={
              customerDOB 
                ? formatDate(customerDOB) 
                : customerDetails?.dob 
                  ? formatDate(customerDetails?.dob) 
                  : ''
            }
            onChange={e => setDateOfBirth(e.target.value)} />
          </div>
        </div>
      </div>
      <div className="part2-container">
        <div className="part2-head-text-box d-flex">
          <div className="part2-text-left-box-black">
            <label>Part II </label>
          </div>
          <label className="part2-head-box-text-normal">
            <label className="ps-2 part1-head-box-text-header"> Claim of Tax Treaty Benefits </label>
            <label> (for chapter 3 purposes only) (see instructions) </label>
          </label>
        </div>
        <div className="declaration-content-container pt-2">
          <div className="cold-md-12">
            <span className="pe-2">
              <strong>9</strong>
            </span>
            <span className="ps-2">I certify that the beneficial owner is a resident of </span>
            <input
              value={FormData2?.country || newData?.country}
              // onChange={e => setResidenceOfBeneficialOwner(e.target.value)}
              className="declaration-content-container-residenceOfBeneficialOwner"
            />
            <span>within the meaning of the income tax treaty between the United States and that country. </span>
          </div>
          <div className="cold-md-12 mt-3">
            <span className="pe-2">
              <strong>10</strong>
            </span>
            <span className="ps-2">
              <strong> Special rates and conditions </strong>(if applicable—see instructions): The beneficial owner is claiming the
              provisions of Article and paragraph
            </span>
            <input
              name="article_and_paragraph"
              value={differentData?.article_and_paragraph || newData?.account_signature_response?.
                w8_ben?.article_and_paragraph}
              onChange={handleInputChange}
              className="declaration-content-container-taxTreatyArticle"
            />
            <span>of the treaty identified on line 9 above to claim a </span>
            <input
              name="rate_of_with_holding"
              value={differentData?.rate_of_with_holding ||  newData?.account_signature_response?.
                w8_ben?.rate_of_with_holding}
              onChange={handleInputChange}
              className="declaration-content-container-withholdingRate"
            />
            % rate of withholding on (specify type of income):
            <input
              name="type_of_income"
              value={differentData?.type_of_income || newData?.account_signature_response?.
                w8_ben?.type_of_income}
              onChange={handleInputChange}
              className="declaration-content-container-typeOfIncome mt-2"
            />
            Explain the additional conditions in the Article and paragraph the beneficial owner meets to be eligible for the rate of
            withholding:
            <input
              name="additional_condition"
              value={differentData?.additional_condition || newData?.account_signature_response?.
                w8_ben?.additional_condition}
              onChange={handleInputChange}
              className="declaration-content-container-additionalConditions mt-2"
            />
          </div>
        </div>
      </div>
      <div className="part3-container mt-2">
        <div className="part3-head-text-box d-flex">
          <div className="part3-text-left-box-black">
            <label>Part III </label>
          </div>
          <label className="part3-head-box-text-normal">
            <label className="ps-2 part3-head-box-text-header">Certification</label>
          </label>
        </div>

        <div className="certification-container-box">
          <label className="certification-container-box-text pt-2">
            Under penalties of perjury, I declare that I have examined the information on this form and to the best of my knowledge and
            belief it is true, correct, and complete. I further certify under penalties of perjury that:
          </label>
          <ul>
            <li>
              I am the individual that is the beneficial owner (or am authorized to sign for the individual that is the beneficial owner) of
              all the income or proceeds to which this form relates or am using this form to document myself for chapter 4 purposes;
            </li>
            <li>The person named on line 1 of this form is not a U.S. person;</li>
            <li>This form relates to:</li>
            <ol type="a">
              <li>income not effectively connected with the conduct of a trade or business in the United States;</li>
              <li>
                income effectively connected with the conduct of a trade or business in the United States but is not subject to tax under an
                applicable income tax treaty;
              </li>
              <li>the partner’s share of a partnership’s effectively connected taxable income; or</li>
              <li>
                the partner’s amount realized from the transfer of a partnership interest subject to withholding under section 1446(f);
              </li>
            </ol>
            <li>
              The person named on line 1 of this form is a resident of the treaty country listed on line 9 of the form (if any) within the
              meaning of the income tax treaty between the United States and that country; and
            </li>
            <li>
              For broker transactions or barter exchanges, the beneficial owner is an exempt foreign person as defined in the instructions
            </li>
          </ul>
          <label className="certification-container-box-text pt-2">
            Furthermore, I authorize this form to be provided to any withholding agent that has control, receipt, or custody of the income
            of which I am the beneficial owner or any withholding agent that can disburse or make payments of the income of which I am the
            beneficial owner.
            <strong> I agree that I will submit a new form within 30 days if any certification made on this form becomes incorrect</strong>
          </label>
        </div>

        <div className="signature-container-box d-flex pt-3">
          <div className="signature-container-inside-box-1 pt-4 d-flex">
            <label className="signature-container-inside-box-1-text ps-2 pt-3">Sign Here</label>
            {/* <label className="signature-container-inside-box-1-arrow-icon ps-2 pe-2 pt-2"> &#x25B6;</label> */}
          </div>
          <div className="signature-container-inside-box-2">
            {/* <div>
              <Input
                type="checkbox"
                className="signature-container-inside-box-2-checkbox pe-2"
                checked={authorizedToSign}
                onChange={e => setAuthorizedToSign(e.target.checked)}
              />
              <label className="ps-2 "> I certify that I have the capacity to sign for the person identified on line 1 of this form.</label>
            </div> */}

            <div>
              {accountHolderType !== 'Individual' || _signatureDataPending?.account_type === 'Joint' || applicationData?.account_type !== 'Individual' ? (
                <>
                  <div>
                    <label>Type your full name in the space below to confirm that you have read and agree to the above document.</label>
                  </div>
                  <div>
                    {customerId === mainAccountsEntity?.customerId ? (


                      < Input
                        type="text"
                        className="signature-container-inside-box-2-input"
                        value={_signatureDataPending?.account_type === 'Joint' ? _signatureDataPending?.w8_ben?.primary_sign : w8benPrimary_sign || ''}
                        readOnly={_signatureDataPending?.account_type === 'Joint'}
                        onChange={e => setw8benPrimary_sign(e.target.value)}
                      />
                    ) : (
                      < Input
                        type="text"
                        className="signature-container-inside-box-2-input"
                        value={_signatureDataPending?.account_type === 'Joint' ? _signatureDataPending?.w8_ben?.primary_sign : w8benPrimary_sign || applicationData?.w8_ben?.primary_sign}
                        readOnly
                      />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <label>Type your full name in the space below to confirm that you have read and agree to the above document.</label>
                  </div>
                  <div>
                    <Input
                      type="text"
                      className="signature-container-inside-box-2-input"
                      value={w8benPrimary_sign || ''}
                      onChange={e => setw8benPrimary_sign(e.target.value)}
                    />
                  </div>
                </>
              )}
            </div>


          </div>
          <div className="signature-container-inside-box-3">
            {/* <Input type="date" value={signatureDate} onChange={e => setSignatureDate(e.target.value)} className="ms-3 mt-4" /> */}
            {currentDate}

            {/* <label>Date (MM-DD-YYYY)</label> */}
          </div>
        </div>
      </div>
      <div className="bottom-box-container d-flex">
        <label>
          <strong>For Paperwork Reduction Act Notice, see separate instructions. </strong>
        </label>
        <label>Cat. No. 25047Z</label>
        <label>
          Form <strong>W-8BEN </strong>(Rev. 10-2021)
        </label>
      </div>

      <Row className="pt-5  text-end no-print justfity-content-end">

        <Col md="12" className=" row-reverse ">
          <Button className="print_btn_w8" onClick={() => handlePrint({ content: () => document.body })}>
            Print
          </Button>
        </Col>
      </Row>
      <Row>
        <FormGroup>
          <Col md="12" className="under-border-div mt-1 mb-1"></Col>
        </FormGroup>
      </Row>


    </div>
  );
};

export default FormDoc;
