import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { updateEntity, updateEntityProperty } from './accounts.reducer';
import { isEmpty } from 'react-jhipster';


interface FinancialServicesProps {
  onFormComplete: (completed: boolean) => void;
}

export interface FinancialServicesRef {
  syncFinancialServicesWithServer: () => Promise<any>;
}

interface IValidationErrors {
  [key: string]: string;
}


const FinancialServices: React.ForwardRefRenderFunction<FinancialServicesRef, FinancialServicesProps> = ({ onFormComplete }: FinancialServicesProps, ref) => {
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const dispatch = useAppDispatch();
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({});
  const [is_employee_of_public_company, setIs_employee_of_public_company] = useState(
    mainAccountsEntity?.is_employee_of_public_company || false
  );
  const [controls_public_traded_company, setControls_public_traded_company] = useState(
    mainAccountsEntity?.controls_public_traded_company || false
  );
  const [companyName, setCompanyName] = useState<string>(mainAccountsEntity.companyName || '');
  const [tradingName, setTradingName] = useState<string>(mainAccountsEntity.tradingName || '');

  const [whoemployedfinancialservicefirm, setwhoemployedfinancialservicefirm] = useState<string>(mainAccountsEntity.whoemployedfinancialservicefirm || '');
  const [familymemberrealtionship, setfamilymemberrelationship] = useState<string>(mainAccountsEntity.familymemberrealtionship || '');
  const [complianceofficername, setcomplianceofficername] = useState<string>(mainAccountsEntity.complianceofficername || '');
  const [complianceofficercontactnumber, setcomplianceofficercontactnumber] = useState<string>(mainAccountsEntity.complianceofficercontactnumber || '+353');
  const [complianceofficeremail, setcomplianceofficeremail] = useState<string>(mainAccountsEntity.complianceofficeremail || '');

  const [employedfinancialservicefirm, setEmployedFinancialServiceFirm] = useState(
    mainAccountsEntity?.employedfinancialservicefirm || 'false'
  );


  useEffect(() => {
    dispatch(updateEntityProperty({ ['employedfinancialservicefirm']: employedfinancialservicefirm }));
    onFormComplete(true)
  }, [employedfinancialservicefirm]);

  useImperativeHandle(ref, () => ({
    syncFinancialServicesWithServer
  }));

  const syncFinancialServicesWithServer = (): Promise<any> => {
    const _errors: IValidationErrors = {};
    if (is_employee_of_public_company === true) {
      if (!companyName) {
        _errors.companyName = "This field is mandatory";
      }
      setValidationErrors((prevErrors) => ({...prevErrors,..._errors }));   
      if (!isEmpty(Object.keys(_errors))) {
        return
      }
    }
    if (controls_public_traded_company === true) {
      if (!tradingName) {
        _errors.tradingName = "This field is mandatory";
      }
      setValidationErrors((prevErrors) => ({...prevErrors,..._errors }));
      if (!isEmpty(Object.keys(_errors))) {
        return
      }
    }
    if (employedfinancialservicefirm === 'true') {
      if (!complianceofficername) {
        _errors.complianceofficername = "This field is mandatory";
      }

      if (!complianceofficercontactnumber || complianceofficercontactnumber === '+353') {
        _errors.complianceofficercontactnumber = "This field is mandatory";
      }

      if (validationErrors.complianceofficercontactnumber && validationErrors.complianceofficercontactnumber !== '') {
        _errors.complianceofficercontactnumber = "Please enter a valid mobile number starting with +353 and followed by 9 digits.";
      }
      // if (!complianceofficeremail) {
      //   _errors.complianceofficeremail = "This field is mandatory";
      // }
      if (validationErrors.complianceofficeremail && validationErrors.complianceofficeremail !== '') {
        _errors.complianceofficeremail = "Invalid email address.";
      }

      if (!whoemployedfinancialservicefirm) {
        _errors.whoemployedfinancialservicefirm = "This field is mandatory";
      }
      if (!familymemberrealtionship) {
        _errors.familymemberrealtionship = "This field is mandatory";
      }
      setValidationErrors((prevErrors) => ({ ...prevErrors, ..._errors }));

      if (!isEmpty(Object.keys(_errors))) {
        return
      }
    }
    setValidationErrors({});

    return dispatch(updateEntity({ ...mainAccountsEntity, last_level: 8 }));
  };
  useEffect(() => {
  }, [complianceofficercontactnumber]);

  const clearValidationError = (field: string) => {
    setValidationErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const phonePattern = /^\+353\d{9}$/;
    if (name === 'companyName') {
      setCompanyName(value);
    }
    if (name === 'tradingName') {
      setTradingName(value);
    }

    if (name === 'complianceofficeremail' && !emailPattern.test(value)) {
      validationErrors.complianceofficeremail = 'Invalid email address';
    } else if (name === 'complianceofficeremail') {
      validationErrors.complianceofficeremail = '';
    }
    if (name === 'complianceofficercontactnumber') {

      if (!phonePattern.test(value)) {
        validationErrors.complianceofficercontactnumber = 'Please enter a valid mobile number starting with +353 and followed by 9 digits.';
      }

      else {
        validationErrors.complianceofficercontactnumber = '';
      }
      setcomplianceofficercontactnumber(value);
    }

    if (name === 'complianceofficeremail' && !emailPattern.test(value)) {
      validationErrors.complianceofficeremail = 'Invalid email address';
    } else if (name === 'complianceofficeremail') {
      validationErrors.complianceofficeremail = '';
    }
    if (name === 'whoemployedfinancialservicefirm') {
      setwhoemployedfinancialservicefirm(value);
    }
    if (name === 'familymemberrealtionship') {
      setfamilymemberrelationship(value);
    }
    if (name === 'complianceofficername') {
      setcomplianceofficername(value);
    }
    if (name === 'complianceofficeremail') {
      setcomplianceofficeremail(value);
    }
    setValidationErrors(prevErrors => ({ ...prevErrors, ...validationErrors }))
    if (value) {
      clearValidationError(name);
    }  
    dispatch(updateEntityProperty({ [name]: value }));
  };

  const handleKeyDown = (event) => {
    const allowedKeys = /[0-9+\b]/;
    const value = event.target.value;

    if (!allowedKeys.test(event.key) && event.keyCode !== 8) {
      event.preventDefault();
    }

    if (value.length >= 13 && event.keyCode !== 8 && !(event.keyCode === 8)) {
      event.preventDefault();
    }

    if (event.key === '+' && value.length !== 0) {
      event.preventDefault();
    }
  };
  const handleEmployeeOfPublicCompanyChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name } = e.target;
    let value = false;

    if (name === "IS_EMPLOYEE_OF_PUBLIC_COMPANY_YES") {
      value = true;
      onFormComplete(false);
    } else if (name === "IS_EMPLOYEE_OF_PUBLIC_COMPANY_NO") {
      value = false;
      onFormComplete(true);
    }

    setIs_employee_of_public_company(value);
    dispatch(updateEntityProperty({ is_employee_of_public_company: value }));
  };

  const handleControlsPublicTradedCompanyChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name } = e.target;
    let value = false;

    if (name === "CONTROLS_PUBLIC_TRADED_COMPANY_YES") {
      value = true;
      onFormComplete(false);
    } else if (name === "CONTROLS_PUBLIC_TRADED_COMPANY_NO") {
      value = false;
      onFormComplete(true);
    }
    setControls_public_traded_company(value);
    dispatch(updateEntityProperty({ controls_public_traded_company: value }));
  };

  const handleEmployedFinancialServiceFirmChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name } = e.target;
    let value = 'false';

    if (name === "EMPLOYED_FINANCIAL_SERVICEFIRM_YES") {
      value = 'true';
      onFormComplete(false);
    } else if (name === "EMPLOYED_FINANCIAL_SERVICEFIRM_NO") {
      value = 'false';
      onFormComplete(true);
    }

    setEmployedFinancialServiceFirm(value);
    dispatch(updateEntityProperty({ employedfinancialservicefirm: value }));
  };

  useEffect(() => {
    validateInputs();
  }, [whoemployedfinancialservicefirm, familymemberrealtionship, complianceofficercontactnumber, complianceofficeremail, complianceofficername]);

  const validateInputs = () => {
    if (whoemployedfinancialservicefirm !== '' && familymemberrealtionship !== '' && complianceofficercontactnumber?.length === 13 && complianceofficeremail !== '' && complianceofficername !== '') {
      onFormComplete(true);
    } else {
      onFormComplete(false);
    }
  }

  return (
    <div>
      <Row>

        {/* new section  */}
      <Col md="12" className="form-firstrow-account-head-custom">
        Regulatory Information 
        </Col>
        <Col md="12" className=" new-form-bold-text pt-3">
        Publicly Traded Companies 
        </Col>
        <Col md="12" className="pt-3 label-account-class">
        Are you an Employee of a Publicly Traded Company? 
        </Col>
        <Col md="12 pt-3 d-flex">
          <FormGroup check>
            <Label check className="label-account-class">
              <Input
                type="checkbox"
                name="IS_EMPLOYEE_OF_PUBLIC_COMPANY_YES"
                id="IS_EMPLOYEE_OF_PUBLIC_COMPANY_YES"
                className="input-account-custom-bgnone"
                checked={is_employee_of_public_company === true}
                onChange={handleEmployeeOfPublicCompanyChange}
              />{' '}
              Yes
            </Label>

          </FormGroup>
          <FormGroup check className='ms-2'>
            <Label check className="label-account-class">
              <Input
                type="checkbox"
                name="IS_EMPLOYEE_OF_PUBLIC_COMPANY_NO"
                id="IS_EMPLOYEE_OF_PUBLIC_COMPANY_NO"
                className="input-account-custom-bgnone"
                checked={is_employee_of_public_company === false}
                onChange={handleEmployeeOfPublicCompanyChange}
              />{' '}
              No
            </Label>
          </FormGroup>
        </Col>

        {mainAccountsEntity?.is_employee_of_public_company === true && (
        <>
        <Col md="12">
              <FormGroup>
                <Label className="label-account-class">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>

                  Please provide details of the Publicly Traded Company and your involvement with it
                  <br />
                  {/* <span className="account-sub-span-custom">Please provide their full name</span> */}
                </Label>
                <Input
                  type="text"
                  name="companyName"
                  id="companyName"
                  value={companyName}
                  defaultValue={mainAccountsEntity?.companyName}
                  className="textfield-style"
                  onChange={handleInputChange}
                />
                {validationErrors.companyName && <div className="text-danger">{validationErrors.companyName}</div>}{' '}

              </FormGroup>
            </Col>
            </>
            )}


        <Col md="12" className="pt-3 label-account-class">
         Do you Control a Publicly Traded Company? 
        </Col>
        <Col md="12" className="pt-3">
        “Control” means the possession, direct or indirect, of the power to direct the management and policies of a company, whether through ownership of voting securities, by contract, or otherwise.
        </Col>
        <Col md="12" className="pt-3">
        Any person that is an officer, director, or at least a 10% shareholder of a company is considered to Control the company. 
        </Col>
        <Col md="12 pt-3 d-flex">
          <FormGroup check>
            <Label check className="label-account-class">
              <Input
                type="checkbox"
                name="CONTROLS_PUBLIC_TRADED_COMPANY_YES"
                id="CONTROLS_PUBLIC_TRADED_COMPANY_YES"
                className="input-account-custom-bgnone"
                checked={controls_public_traded_company === true}
                onChange={handleControlsPublicTradedCompanyChange}
              />{' '}
              Yes
            </Label>

          </FormGroup>
          <FormGroup check className='ms-2'>
            <Label check className="label-account-class">
              <Input
                type="checkbox"
                name="CONTROLS_PUBLIC_TRADED_COMPANY_NO"
                id="CONTROLS_PUBLIC_TRADED_COMPANY_NO"
                className="input-account-custom-bgnone"
                checked={controls_public_traded_company === false}
                onChange={handleControlsPublicTradedCompanyChange}
              />{' '}
              No
            </Label>
          </FormGroup>
        </Col>
        
      {mainAccountsEntity?.controls_public_traded_company === true && (
        <>
        <Col md="12">
              <FormGroup>
                <Label className="label-account-class">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>

                  Please provide details of the Publicly Traded Company and your involvement with it
                  <br />
                  {/* <span className="account-sub-span-custom">Please provide their full name</span> */}
                </Label>
                <Input
                  type="text"
                  name="tradingName"
                  id="tradingName"
                  value={tradingName}
                  defaultValue={mainAccountsEntity?.tradingName}
                  className="textfield-style"
                  onChange={handleInputChange}
                />
                {validationErrors.tradingName && <div className="text-danger">{validationErrors.tradingName}</div>}{' '}

              </FormGroup>
            </Col>
            </>
            )}
        
{/* new section end */}

        <Col md="12" className=" new-form-bold-text pt-3">
        Financial Services Employees Only
        </Col>
        <Col md="12" className="pt-3">
          Complete only if you or anyone in your immediate family is employed by a Financial Services firm, including banks, investment
          advisors, brokers
        </Col>
        <Col md="12" className="pt-3 label-account-class">
        Are you or anyone in your immediate family employed by a financial services firm?
        </Col>
        <Col md="12 pt-3 d-flex">
          <FormGroup check>
            <Label check className="label-account-class">
              <Input
                type="checkbox"
                name="EMPLOYED_FINANCIAL_SERVICEFIRM_YES"
                id="EMPLOYED_FINANCIAL_SERVICEFIRM_YES"
                className="input-account-custom-bgnone"
                checked={employedfinancialservicefirm === 'true'}
                onChange={handleEmployedFinancialServiceFirmChange}
              />{' '}
              Yes
            </Label>

          </FormGroup>
          <FormGroup check className='ms-2'>
            <Label check className="label-account-class">
              <Input
                type="checkbox"
                name="EMPLOYED_FINANCIAL_SERVICEFIRM_NO"
                id="EMPLOYED_FINANCIAL_SERVICEFIRM_NO"
                className="input-account-custom-bgnone"
                checked={employedfinancialservicefirm === 'false'}
                onChange={handleEmployedFinancialServiceFirmChange}
              />{' '}
              No
            </Label>
          </FormGroup>
        </Col>
        {mainAccountsEntity?.employedfinancialservicefirm === 'true' && (
          <>
            <Col md="12">
              <FormGroup>
                <Label className="label-account-class">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>

                  Who Is Employed by the Financial Services Firm?
                  <br />
                  <span className="account-sub-span-custom">Please provide their full name</span>
                </Label>
                <Input
                  type="text"
                  name="whoemployedfinancialservicefirm"
                  id="whoemployedfinancialservicefirm"
                  value={whoemployedfinancialservicefirm}
                  defaultValue={mainAccountsEntity?.whoemployedfinancialservicefirm}
                  className="textfield-style"
                  onChange={handleInputChange}
                />
                {validationErrors.whoemployedfinancialservicefirm && <div className="text-danger">{validationErrors.whoemployedfinancialservicefirm}</div>}{' '}

              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label className="label-account-class">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>

                  If a Family Member, Provide Relationship</Label>
                <Input
                  type="text"
                  name="familymemberrealtionship"
                  id="familymemberrealtionship"
                  value={familymemberrealtionship}
                  defaultValue={mainAccountsEntity?.familymemberrealtionship}
                  className="textfield-style"
                  onChange={handleInputChange}
                />
                {validationErrors.familymemberrealtionship && <div className="text-danger">{validationErrors.familymemberrealtionship}</div>}{' '}

              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup>
                <Label className="label-account-class">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>

                  Compliance Officer Name
                  <br />
                  <span className="account-sub-span-custom">
                    Please provide the details of the Compliance Officer at the Financial Services firm
                  </span>
                </Label>
                <Input
                  type="text"
                  name="complianceofficername"
                  id="complianceofficername"
                  value={complianceofficername}
                  defaultValue={mainAccountsEntity?.complianceofficername}
                  className="textfield-style"
                  onChange={handleInputChange}
                />
                {validationErrors.complianceofficername && <div className="text-danger">{validationErrors.complianceofficername}</div>}{' '}

              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup>
                <Label className="label-account-class">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>

                  Compliance Officer Contact Number
                  <br />
                  <span className="account-sub-span-custom">
                    Please provide the details of the Compliance Officer at the Financial Services firm
                  </span>
                </Label>
                <Input
                  type="tel"
                  name="complianceofficercontactnumber"
                  id="complianceofficercontactnumber"
                  value={complianceofficercontactnumber}
                  defaultValue={mainAccountsEntity?.complianceofficercontactnumber || '+353'}
                  onChange={e => handleInputChange(e)}
                  className="textfield-style"
                  onKeyDown={handleKeyDown}

                />
                {validationErrors.complianceofficercontactnumber && <div className="text-danger">{validationErrors.complianceofficercontactnumber}</div>}{' '}

              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup>
                <Label className="label-account-class">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>

                  Compliance Officer Email Address
                  <br />
                  <span className="account-sub-span-custom">
                    Please provide the details of the Compliance Officer at the Financial Services firm
                  </span>
                </Label>
                <Input
                  type="text"
                  name="complianceofficeremail"
                  id="complianceofficeremail"
                  value={complianceofficeremail}
                  defaultValue={mainAccountsEntity?.complianceofficeremail}
                  className="textfield-style"
                  onChange={handleInputChange}
                />
                {validationErrors.complianceofficeremail && <div className="text-danger">{validationErrors.complianceofficeremail}</div>}{' '}

              </FormGroup>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default forwardRef(FinancialServices);

