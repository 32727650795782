import React, { useEffect, useState } from 'react';
import { ValidatedField } from 'react-jhipster';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Spinner } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FieldError, useForm } from 'react-hook-form';
import './login.scss';
import { customerLoginOtp, customerValidateEmail, postLogin } from '../account/register/register.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
 
import './style.scss';
import './successmodal.scss';
import OtpInput from 'react-otp-input';
import { getPendingTask } from '../profile/profile.reducer';
import CommonToast from '../components/CommonToast';
import ResetPasswordModal from '../components/ResetPassword/ResetPasswordModal';
 
 
const SigninModal = () => {
  const dispatch = useAppDispatch();
  const initialPath = useAppSelector(state => state.register.initialPath);
 
  const navigate = useNavigate();
  const [showSignInModal, setShowSignInModal] = useState(true);
  const [showSignInOtpModal, setShowSignInOtpModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const signatureData = useAppSelector(state => state.accounts.signatureData);
  const [toastVisible, setToastVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState(300);
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const [resetPasswordModalVisible,setResetPasswordModalVisible]=useState(false)
 
  const [secondaryCustomerId, setSecondaryCustomerId] = useState('')
 
  const customerTask = useAppSelector(state => state.customer)
 
  const toggleSignInModal = () => {
    setShowSignInModal(!showSignInModal);
    navigate('/');
  };
  const toggleSignInOtpModal = () => {
    setShowSignInOtpModal(!showSignInOtpModal);
    navigate('/');
  };
  const handleModalClose = () => {
    toggleSignInModal();
  };
 
  const [Username, setUsername] = useState('');
 
  const [otp, setOtp] = useState('')
  const [transactionId, setTransactionId] = useState()
 
  const [Password, setPassword] = useState('');
 
  const sendOtp = async () => {
    const data = {
      email: Username,
    };
 
    try {
      const response: any = await dispatch(customerLoginOtp(data));
      setTransactionId(response?.payload?.transaction_id)
      if (response.payload && response.payload.transaction_id) {
 
        setToastMessage('Please check your email for a secure code');
        setToastType('success');
        setToastVisible(true);
        startTimer();
        setTimeout(() => {
          setShowSignInOtpModal(true);
          setShowSignInModal(false);
 
        }, 3000);
 
      } else {
        setToastMessage(response?.payload?.detail);
        setToastType('error');
        setToastVisible(true);
        setShowErrorModal(true);
      }
    } catch (error) {
      setToastMessage(error);
      setToastType('error');
      setToastVisible(true);
 
    }
  }
 
  const startTimer = () => {
    setLoading(true);
    setTimeLeft(300);
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setLoading(false);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
 
    return () => clearInterval(timer);
  };
 
  useEffect(() => {
    const cleanup = startTimer();
    return cleanup;
  }, []);
 
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };
 
 
  const handleCheckIsValidUser = async () => {
    const useData = {
      username: Username,
      password: Password,
      userType: "ROLE_USER"
    }
 
    try {
      const response: any = await dispatch(customerValidateEmail(useData));
 
      if (response?.payload?.status === true) {
        sendOtp();
      }
      else {
        setToastMessage(response?.payload?.message);
        setToastType('error');
        setToastVisible(true);
      }
    }
    catch (error) {
      setToastMessage(error);
      setToastType('error');
      setToastVisible(true);
 
    }
  };
 
  const handleLoginSubmit = async () => {
    const data: any = {
      username: Username,
      password: Password,
      userType: 'ROLE_USER',
      otp,
      transaction_id: transactionId,
    };
    try {
      const response: any = await dispatch(postLogin(data));
      if (response.payload.data?.access_token) {
        setToastMessage((prev) => "Login Successful");
        setToastType('success');
        setToastVisible(true);
        setShowSignInOtpModal(false);
        setLoading(false);
        startTimer();
        const pendingTaskResponse: any = await dispatch(getPendingTask({}));
        setTimeout(() => {
 
          const accessToken = localStorage.getItem('jhi-authenticationToken');
          const currentPath = window.location.pathname;
          if (accessToken) {
          if(response.payload?.data?.is_temp_user){
            setResetPasswordModalVisible(!resetPasswordModalVisible)
                 }
                 else{
                  handleNavigationOnSignIn();
                 }
          }
          else {
            navigate('/signin');
            window.location.reload()
 
          }
 
        }, 2000);
 
      }
      else {
 
        setToastMessage((prev) => response?.payload?.response?.data?.detail);
        setToastType('error');
        setToastVisible(true);
 
      }
    } catch (error) {
      setToastMessage((prev) => "Invalid Credentials");
      setToastType('error');
      setToastVisible(true);
 
 
    }
  };
 
  const handleNavigationOnSignIn=()=>{
    if (initialPath === "/user-profile") {
      navigate('/user-profile');
      window.location.reload()
 
    } else if (initialPath === '/admin-profile') {
      navigate('/admin-profile')
 
    } else if (initialPath === '/accounts') {
      navigate("/accounts")
      window.location.reload()
    }
      else if (initialPath === '/company') {
        navigate('/company')
        window.location.reload()
 
      }
  }
 
  const handleCloseSuccessModal = () => {
    window.location.reload();
    setShowSuccessModal(false);
    // navigate('/');
  };
 
 
  const handleResendOtp = async () => {
    try {
      await sendOtp();
    } catch (error) {
      setToastMessage('Failed to resend OTP. Please try again.');
      setToastType('error');
      setToastVisible(true);
    }
  };
 
  const {
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });
 const handleClose=()=>{
  toggleSignInModal();
 }
  return (
    <>
      <>
 
        <CommonToast
          visible={toastVisible}
          onClose={() => setToastVisible(false)}
          title={toastType === 'success' ? 'Success' : 'Error'}
          description={toastMessage}
          type={toastType}
          isAutoClose={true}
 
        />
      </>
      <Modal isOpen={showSignInModal} toggle={toggleSignInModal} className="mt-4">
        
        <Row
          style={{
            backgroundImage: "url('../../../content/images/starlight-login.webp')",
            backgroundPosition: 'center top',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            border: '1px solid white',
          }}
          className="justify-content-center"
        >
              
          <Col md="7">
    
          <Row className="mt-5 align-items-center">
                <Col className="d-flex justify-content-center" xs={12} md={12}>
                  <label style={{ fontSize: '30px', fontWeight: '500', fontFamily: 'Raleway', color: 'white' }}>Starlight Wealth</label>
                </Col>
                <Col className="d-flex justify-content-between align-items-center" xs={12} md={12}>
                  <label style={{ fontSize: '20px', fontWeight: '500', fontFamily: 'Raleway', color: 'white' ,marginTop:'20px',marginLeft:"18px"}}>Log In</label>
                  <Button className="reg-close" onClick={handleClose} style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontSize: '10px',
                      fontWeight: 'bold',
                      cursor: 'pointer'
                    }}>
                    X
                  </Button>
                </Col>
                <div className="login-box d-flex" style={{ fontFamily: 'Montserrat',marginLeft:"18px" }}>
                <span className="head1">{`Don't have an account? `}</span>
                <Link to="/login" className="logincolour ps-2">
                 <span> Sign Up</span>
                </Link>
              </div>
              </Row>
              
            <ModalBody>
              <Row>
                <Col md="12" style={{paddingRight:""}} className="mt-2">
                  <ValidatedField
                    name="Firstname"
                    label="Username"
                    placeholder="Enter your Username"
                    required
                    autoFocus
                    onChange={e => setUsername(e.target.value)}
                    className="validated-field1"
                    data-cy="username"
                    validate={{ required: 'Username cannot be empty!' }}
                    style={{ padding: '3px 12px' }}
                    error={errors.username as FieldError}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12" className="mt-4 tt">
                  <ValidatedField
                    name="Password"
                    label="Password"
                    placeholder="Enter your password"
                    type="password"
                    onChange={e => setPassword(e.target.value)}
                    className="validated-field1"
                    required
                    data-cy="password"
                    validate={{ required: 'password cannot be empty!' }}
                    error={errors.password as FieldError}
                    style={{ padding: '3px 12px' }}
                  />
                </Col>
              </Row>
            </ModalBody>
 
            <ModalFooter className="d-flex flex-column align-items-center">
              <Button className="signin-btn-style mb-2"
                onClick={() => { handleCheckIsValidUser() }}
                type="submit">
                Submit
              </Button>
              <Link className="d-flex flex-column align-items-right" to="/forgot" style={{ color: "#2a9fd6", fontSize: 12, fontFamily: 'Montserrat' }}>
                Forgot Password
              </Link>
 
            </ModalFooter>
          </Col>
        </Row>
      </Modal>
 
      {/* //otp */}
      <Modal isOpen={showSignInOtpModal} toggle={toggleSignInOtpModal} className="mt-4">
  <Row
    style={{
      backgroundImage: "url('../../../content/images/starlight-login.webp')",
      backgroundPosition: 'center top',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      border: '1px solid white',
    }}
    className="justify-content-center"
  >
    <Col md="10">
      <ModalBody>
        <Row>
          <Col className="d-flex justify-content-center" xs={12} md={12}>
            <label style={{ fontSize: '30px', fontWeight: '500', fontFamily: 'Raleway', color: 'white' }}>Starlight Wealth</label>
          </Col>
          <Col>
            <label style={{ fontSize: '20px', fontWeight: '500', fontFamily: 'Raleway', color: 'white', marginTop: '20px', marginLeft: "5px" }}>Verification Required</label>
            <Button className="reg-close" onClick={handleClose} style={{
              position: 'absolute',
              top: '10px',
              right: '-47px',
              background: 'transparent',
              border: 'none',
              color: 'white',
              fontSize: '10px',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}>
              X
            </Button>
          </Col>
          <Col md="12" className="mt-2 tt">
            <Label for="totp" className="label-account-class" style={{ color: '#9fcdda' }}>
              Please enter the secure code sent to your registered email address
            </Label>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={props => <input {...props} />}
            />
            {loading && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <Spinner size="sm" color="primary" style={{ marginRight: '5px' }} />
                <span style={{ color: 'white', fontFamily: 'Montserrat', fontSize: '14px' }}>{formatTime(timeLeft)} remaining</span>
              </div>
            )}
            {!loading && timeLeft === 0 && (
              <button className="signin-btn-style" onClick={handleResendOtp}>
                Resend OTP
              </button>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <Button className="signin-btn-style" onClick={() => { handleLoginSubmit() }} type="submit">
          Submit
        </Button>
      </ModalFooter>
    </Col>
  </Row>
</Modal>

<ResetPasswordModal showSignInModal={resetPasswordModalVisible} handleClose={(isClosed)=>{setResetPasswordModalVisible(isClosed)
}}/>
    </>
  );
};
 
export default SigninModal;