// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head {
  font-size: 15px;
  text-align: start;
}

.head1 {
  color: white;
  font-size: 14px;
  text-align: start;
}

.logincolour {
  color: #9fcdda;
  font-size: 14px;
  font-weight: 600;
}

.validated-field1 {
  color: #9fcdda;
  font-weight: 400;
  font-size: 15px;
  font-family: "Montserrat";
}

.validated-field1 ::-moz-placeholder {
  font-size: 13px;
  color: #668086;
  font-family: "Montserrat";
}

.validated-field1 ::placeholder {
  font-size: 13px;
  color: #668086;
  font-family: "Montserrat";
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/login/style.scss"],"names":[],"mappings":"AAAA;EAEE,eAAA;EACA,iBAAA;AAAF;;AAEA;EACE,YAAA;EACA,eAAA;EACA,iBAAA;AACF;;AACA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAEF;;AAEA;EACE,cAAA;EACA,gBAAA;EACA,eAAA;EACA,yBAAA;AACF;;AAGE;EACE,eAAA;EACA,cAAA;EACA,yBAAA;AAAJ;;AAHE;EACE,eAAA;EACA,cAAA;EACA,yBAAA;AAAJ","sourcesContent":[".head {\n\n  font-size: 15px;\n  text-align: start;\n}\n.head1 {\n  color: white;\n  font-size: 14px;\n  text-align: start;\n}\n.logincolour {\n  color: #9fcdda;\n  font-size: 14px;\n  font-weight: 600;\n}\n\n\n.validated-field1 {\n  color: #9fcdda;\n  font-weight: 400;\n  font-size: 15px;\n  font-family: 'Montserrat';\n}\n\n.validated-field1 {\n  ::placeholder {\n    font-size: 13px;\n    color: #668086;\n    font-family: 'Montserrat';\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
