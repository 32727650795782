import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IAccounts, defaultValue } from 'app/shared/model/accounts.model';
import { ICustomer } from 'app/shared/model/customer.model';

const initialState: EntityState<IAccounts> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  countryList: [],

  signatureData: null,
};

interface SignatureArgs {
  accountId: string;
  signaturetype: string;
  signed_by: string;
  signed_date: string;
}
interface W8BenForm {
  signed_by: string;
  signed_date: string;
  mailing_address: MailingAddress;
  ssn: string;
  ftn: boolean;
  reference_number: string;
  beneficial_owner_residence: string;
  article_and_paragraph: string;
  rate_of_with_holding: number;
  type_of_income: string;
  additional_condition: string;
  eligibility_explanation: string;
}

interface MailingAddress {
  street: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
}
interface SubmitW8BenFormArgs {
  accountId: string;
  payload: W8BenForm;
}

const apiUrl = `${SERVER_API_URL}api/accounts`;
// const states = `${SERVER_API_URL}api/public/state/list`;
const countries = `${SERVER_API_URL}api/public/country/list`;
const signatureList = `${SERVER_API_URL}api/accounts/sign`;
const stateList = `${SERVER_API_URL}api/public/state/list`;
const ibkr = `${SERVER_API_URL}api/accounts/active/ibkr`;
const bankList = `${SERVER_API_URL}api/public/bank/list`;
const AGREEMENTS_API_URL = `${SERVER_API_URL}api/accounts/ibkr/agreements`;
const w8ben = `${SERVER_API_URL}api/{accountId}/w8ben`;

export const getEntities = createAsyncThunk('accounts/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}`;
  return axios.get<IAccounts[]>(requestUrl);
});

export const getIbkr = createAsyncThunk('accounts/fetch_active_ibkr', async () => {
  try {
    const response = await axios.get(ibkr);
    return response?.data;
  } catch (error) {
    throw new Error('Failed to fetch active ibkr accounts');
  }
});

export const getAgreements = createAsyncThunk('accounts/fetchAgreements', async () => {
  try {
    const response = await axios.get(AGREEMENTS_API_URL);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch agreements');
  }
});

export const submitW8BenForm = createAsyncThunk<
  W8BenForm, // Return type of the fulfilled action
  { accountId: string; payload: any }, // Argument type of the thunk action
  { rejectValue: string } // Type for rejected action's payload
>(
  'accounts/submit_w8ben_form',
  async ({ accountId, payload }, thunkAPI) => {
    try {
      const requestUrl = `${SERVER_API_URL}api/forms/${accountId}/w8ben`;
      const response = await axios.post<W8BenForm>(requestUrl, payload);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('Failed to submit W8Ben form');
    }
  }
);


export const getEntity = createAsyncThunk(
  'accounts/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IAccounts>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getState = createAsyncThunk('accounts/fetch_state_list', async () => {
  try {
    const response = await axios.get(stateList);
    return response?.data;
  } catch (error) {
    throw new Error('Failed to fetch country list');
  }
});

export const getCountryList = createAsyncThunk('accounts/fetch_country_list', async () => {
  try {
    const response = await axios.get(countries);
    return response?.data;
  } catch (error) {
    throw new Error('Failed to fetch country list');
  }
});

export const getCountriesList = createAsyncThunk('accounts/fetch_countries_list', async () => {
  try {
    const response = await axios.get(countries);
    return response?.data;
  } catch (error) {
    throw new Error('Failed to fetch country list');
  }
});

export const getBankList = createAsyncThunk('accounts/fetch_bank_list', async () => {
  try {
    const response = await axios.get(bankList);
    return response?.data;
  } catch (error) {
    throw new Error('Failed to fetch country list');
  }
});

export const getSignature = createAsyncThunk('accounts/fetch_sign_list', async (accountId: string) => {
  try {
    const response = await axios.get(`${signatureList}/${accountId}`);
    return response?.data;
  } catch (error) {
    throw new Error('Failed to fetch country list');
  }
});

export const signature = createAsyncThunk(
  'account/signature',
  async ({ accountId, signaturetype, signed_by, signed_date }: SignatureArgs, thunkAPI) => {
    try {
      const signatureUrl = `${SERVER_API_URL}api/accounts/sign/${accountId}/${signaturetype}`;
      const response = await axios.post(signatureUrl, { signed_by, signed_date });
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const uploadDocument = createAsyncThunk<void, any>('account/upload_document', async ({ accountId, file, docType }, thunkAPI) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const documentUploadUrl = `${SERVER_API_URL}api/document/${accountId}?doc_type=${docType}`;

    const response: any = await axios.post(documentUploadUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const createEntity = createAsyncThunk(
  'accounts/create_entity',
  async (entity: IAccounts, thunkAPI) => {
    try {
      const result = await axios.post<IAccounts>(apiUrl, cleanEntity(entity));

      return result;
    } catch (error) {
      return error;
    }
  },
  { serializeError: serializeAxiosError },
);

export const createEnquiryEntity = createAsyncThunk(
  'enquires/create',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<ICustomer>(apiUrl, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'accounts/update_entity',
  async (entity: any, thunkAPI) => {
    try {
      const cleanedEntity = cleanEntity(entity);
      const result = await axios.put<IAccounts>(`${apiUrl}/${entity?.accountId}`, cleanedEntity);
      return result;
    } catch (error) {
      console.error('Update entity error:', error);
      return thunkAPI.rejectWithValue(error?.response?.data || 'Unknown error');
    }
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'accounts/partial_update_entity',
  async (entity: IAccounts, thunkAPI) => {
    const result = await axios.patch<IAccounts>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'accounts/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IAccounts>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const AccountsSlice = createEntitySlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccountLastLevel(state, action) {
      state.entity.last_level = action.payload;
    },
    setCountriesList(state, action) {
      state.countriesList = action.payload;
    },
    setStatesList(state, action) {
      // state.statesList = action.payload;
    },
    reset(state) {
      state.entity = { ...defaultValue, last_level: 1 };
      state.signatureData = {};
    },
    updateEntityProperty(state, action) {
      
      const { poaDocument, passportDoc } = action.payload;
      if (poaDocument !== undefined) {
        state.entity.proofOfAddressDoc = poaDocument;
      }
      if (passportDoc !== undefined) {
        state.entity.passportDoc = passportDoc;
      }

      state.entity = { ...state.entity, ...action.payload };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload.data.accountHolderType === 'Organization'){
          if (action.payload.data.last_level >= 101) {
            state.entity = { ...defaultValue, last_level: 1 };
          } else {
            state.entity = { ...state.entity, ...action.payload.data };
          }
        }else{
          if (action.payload.data.last_level >= 105) {
            state.entity = { ...defaultValue, last_level: 1 };
          } else {
            state.entity = { ...state.entity, ...action.payload.data };
          }
        }
        
        return state;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = { ...defaultValue, last_level: 0 };
      })

      .addCase(uploadDocument.pending, state => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })

      .addCase(getCountryList.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = { ...state.entity, countryList: action.payload };
      })
      .addCase(getSignature.fulfilled, (state, action) => {
        state.loading = false;
        state.signatureData = action.payload;
      })
      .addCase(getSignature.rejected, (state, action) => {
        state.loading = false;
        state.signatureData = {};
      })

      .addCase(createEnquiryEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = { ...state.entity, enquiries: action.payload };
      })
      .addCase(getCountriesList.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = { ...state.entity, countryList: action.payload };
      })
      .addCase(getState.fulfilled, (state, action) => {
        state.loading = false;
        // state.statesList = action.payload;
        state.entity = { ...state.entity, statesList: action.payload };
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = { ...state.entity, ...action.payload.data };
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { setAccountLastLevel, setCountriesList, setStatesList, reset, updateEntityProperty } = AccountsSlice.actions;

export default AccountsSlice.reducer;
