import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Col, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { createEntity, updateEntity, updateEntityProperty } from './accounts.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IAccounts } from 'app/shared/model/accounts.model';

interface InitialRequirementProps {
  onFormComplete: (completed: boolean) => void;
}

export interface InitialRequirementRef {
  syncInitialRequirementWithServer: () => Promise<any>;
}

const InitialRequirement: React.ForwardRefRenderFunction<InitialRequirementRef, InitialRequirementProps> = ({ onFormComplete }: InitialRequirementProps, ref) => {
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const dispatch = useAppDispatch();
  const [checkBoxStates, setCheckBoxStates] = useState({
    over18: mainAccountsEntity.isAgeOverAgreed || false,
    beneficialOwner: mainAccountsEntity.beneficialOwnerAgreed || false,
    residentIreland: mainAccountsEntity.residentInIrelandAgreed || false,
    taxResidentIreland: mainAccountsEntity.taxResidentInIrelandAgreed || false,
    minimumInvestment: mainAccountsEntity.lumpSumAvailableToInvest || false,
    isTaxResidentInIreland: mainAccountsEntity.isTaxResidentInIreland || false,
  });
  const [accountHolderType, setAccountHolderType] = useState('Individual');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const customerDetails = useAppSelector(state => state.customer.entities);

  useEffect(() => {
    if (customerDetails) {
      setName(customerDetails?.firstName || '');
      setLastName(customerDetails?.lastName || '');
      setEmail(customerDetails?.email || '');
      setPhone(customerDetails?.phone || '');
    }
  }, [customerDetails]);

  useImperativeHandle(ref, () => ({
    syncInitialRequirementWithServer
  }));


  const syncInitialRequirementWithServer = async (): Promise<any> => {
    // if(Object.prototype.hasOwnProperty.call(Object(mainAccountsEntity), 'accountId') && mainAccountsEntity?.accountId !== '') {
    if (mainAccountsEntity?.accountId && mainAccountsEntity.accountId !== '') {

      return dispatch(updateEntity({ ...mainAccountsEntity, last_level: 2 }));
    } else {
      const body: IAccounts = {
        accountId: '',
        accountMasterType: 'Starlight Smart Reserve',
        accountHolderTitle: mainAccountsEntity.accountHolderTitle || '',
        accountHolderEmail: mainAccountsEntity.accountHolderEmail || '',
        accountHolderType: accountHolderType || '',
        accountHolderSurname: mainAccountsEntity.accountHolderSurname || '',
        accountHolderName: name || '',
        productType: mainAccountsEntity.accountHolderType ? [mainAccountsEntity.accountHolderType] : [],
        accountHolderMaritalStatus: "Married",
        accountHolderDependents: 0,
        accountHolderMobileNumber: phone || '',
        investmentAmount: mainAccountsEntity.investmentAmount || 0,
        isAgeOverAgreed: checkBoxStates.over18,
        beneficialOwnerAgreed: checkBoxStates.beneficialOwner,
        residentInIrelandAgreed: checkBoxStates.residentIreland,
        taxResidentInIrelandAgreed: checkBoxStates.taxResidentIreland,
        employmentIncomePercentage: null,
        propertyPercentage: null,
        giftPercentage: null,
        investmentPercentage: null,
        otherPercentage: null,
        totalNetWorth: null,
        liquidNetWorth: null,
        totalNetIncome: null,
        accountOwnerName: name || '',
        nameOfBank: 'Bank of Ireland',
        isPep: false,
        isFamilyPep: false,
        financialEmployee: false,
        securityQuestionAnswer: null,
        securityQuestionAnswer1: null,
        securityQuestionAnswer2: null,
        iban: '',
        isRegisteredInIreland: false,
        isTaxResidentInIreland: false,
        lumpSumAvailableToInvest: mainAccountsEntity.lumpSumAvailableToInvest,
        descriptionOfBusiness: '',
        last_level: 2,
        is_company_account: false,
        citizenship: 'Ireland',
        joint_details: {
          jointnametitle: '',
          jointfirstname: '',
          jointsurname: '',
          jointmaritalstatus: '',
          jointdependents: 1,
          jointmobilenumber: mainAccountsEntity.jointmobilenumber || '',
          jointaddressline1: '',
          jointaddressline2: '',
          jointtownorcity: '',
          jointcountry: '',
          jointpostcode: '',
          jointppsnumber: '',
          jointistaxresident: false,
          jointothertaxresidence: '',
          jointemploymentstatus: 'EMPLOYED',
          jointoccupation: '',
          jointemployername: '',
          jointemployerbusiness: '',
          jointemployeraddress: null,
          jointisapubliclylistedcompany: false,
          joint_username: mainAccountsEntity.joint_username || '',
          jointdob: mainAccountsEntity.jointdob || '',
          jointownership: 50,
          jointissuedcountry: 'Ireland',
          jointstateorprovince: "Galway",
          jointcountryofbirth: 'Ireland',
        },
      };

      try {
        const response = await dispatch(createEntity(body));
        return response;
      } catch (error) {
        console.error('Error creating entity:', error);
        throw error;
      }
    }

  };

  const handleCheckboxChange = (e: any) => {
    const { name: checkedName, checked } = e.target;
    setCheckBoxStates(prevState => ({
      ...prevState,
      [checkedName]: checked,
    }));
    if (checkedName === 'minimumInvestment') {

      dispatch(updateEntityProperty({ ['lumpSumAvailableToInvest']: checked }));
    } else {
      dispatch(updateEntityProperty({ [checkedName]: checked }));
    }
  };

  const handleAccountType = (e: any) => {
    setAccountHolderType(e.target.value);
    dispatch(updateEntityProperty({ accountHolderType: e.target.value }));
  };

  useEffect(() => {
    if (checkBoxStates.over18 &&
      checkBoxStates.beneficialOwner &&
      checkBoxStates.residentIreland &&
      checkBoxStates.taxResidentIreland &&
      checkBoxStates.minimumInvestment &&
      checkBoxStates.isTaxResidentInIreland && accountHolderType) {
      onFormComplete(true);
    } else {
      onFormComplete(false);
    }
  }, [checkBoxStates, accountHolderType])

  return (
    <div>
      <Row className="form-firstrow-account-custom pt-3">
        <Col md="12" className="form-firstrow-account-head-custom">
          Initial Requirements Check
        </Col>
        <span className="account-sub-span-custom">You must be able to meet all of these requirements to open an account</span>
      </Row>
      <Row className="pb-3">
        <Row>
          <Col md="12">
            <FormGroup check>
              <Label className="label-account-class" check>
                <Input
                  type="checkbox"
                  name="over18"
                  checked={checkBoxStates.over18}
                  className="input-front-account-custom-bgnone"
                  onChange={handleCheckboxChange}
                />{' '}
                You are over 18
              </Label>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup check>
              <Label className="label-account-class" check>
                <Input
                  type="checkbox"
                  name="beneficialOwner"
                  checked={checkBoxStates.beneficialOwner}
                  className="input-front-account-custom-bgnone"
                  onChange={handleCheckboxChange}
                />{' '}
                You will be the beneficiary owner(s) of the account
              </Label>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup check>
              <Label className="label-account-class" check>
                <Input
                  type="checkbox"
                  name="residentIreland"
                  checked={checkBoxStates.residentIreland}
                  className="input-front-account-custom-bgnone"
                  onChange={handleCheckboxChange}
                />{' '}
                You are resident in Ireland
              </Label>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup check>
              <Label className="label-account-class" check>
                <Input
                  type="checkbox"
                  name="taxResidentIreland"
                  checked={checkBoxStates.taxResidentIreland}
                  className="input-front-account-custom-bgnone"
                  onChange={handleCheckboxChange}
                />{' '}
                You are tax resident in ireland and have a PPS number
              </Label>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup check>
              <Label className="label-account-class" check>
                <Input
                  type="checkbox"
                  name="minimumInvestment"
                  checked={checkBoxStates.minimumInvestment}
                  className="input-front-account-custom-bgnone"
                  onChange={handleCheckboxChange}
                />{' '}
                You have a lump sum of minimum €50,000 available to invest
              </Label>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup check>
              <Label className="label-account-class" check>
                <Input
                  type="checkbox"
                  name="isTaxResidentInIreland"
                  checked={checkBoxStates.isTaxResidentInIreland}
                  className="input-front-account-custom-bgnone"
                  onChange={handleCheckboxChange}
                />{' '}
                Your Investment Objective is to preserve capital and generate a return that reflects the European Central Bank (ECB) deposit
                rate
              </Label>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>Account Type
              </Label>
              <Input
                type="select"
                name="accountHolderType"
                id="accountHolderType"
                className="textfield-style"
                value={accountHolderType}
                defaultValue={mainAccountsEntity.accountHolderType}
                onChange={handleAccountType}
              >
                <option value="Individual">Individual</option>
                <option value="Joint" selected>Joint</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </Row>
    </div>
  );
};
export default forwardRef(InitialRequirement);
