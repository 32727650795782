import Lottie from 'lottie-react';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import animationData from '../../../content/lottie/upload_loader.json';
import { FileEarmarkMedicalFill, Trash } from 'react-bootstrap-icons';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ValidatedField, log } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { uploadDocument, getCountriesList, updateEntity, updateEntityProperty, getCountryList, getState } from '../accounts/accounts.reducer';
import { toast } from 'react-toastify';
import DropZone from 'app/modules/DropZone';
import CommonToast from 'app/modules/components/CommonToast';
import { getPendingTask } from 'app/modules/profile/profile.reducer';
import { getCustomerDetails } from '../customer/customer.reducer';
interface primaryDetailsProp {
  onFormComplete: (completed: boolean) => void;
}

export interface primaryDetailsRef {
  syncPrimaryDetailsWithServer: () => Promise<any>;
}

const PrimaryApplication: React.ForwardRefRenderFunction<primaryDetailsRef, primaryDetailsProp> = ({ onFormComplete }: primaryDetailsProp, ref) => {
  const MANDATORY: string = 'This field is mandatory';
  const dispatch = useAppDispatch();
  const customerDetails = useAppSelector(state => state.customer.entities);
  const [isUploadingOne, setIsUploadingOne] = useState(false);
  const [isUploadingTwo, setIsUploadingTwo] = useState(false);
  const [investError, setInvestError] = useState('');
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const accountsEntity = sessionStorage?.getItem('accountid');
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const [countryList, setCountryList] = useState<string[]>(mainAccountsEntity?.countryList || []);

  const [nameTitle, setNameTitle] = useState<string>(mainAccountsEntity?.accountHolderTitle || 'Mr');
  const [accountHolderName, setAccountHolderName] = useState<string>(mainAccountsEntity?.accountHolderName || '');
  const [lastName, setLastName] = useState<string>(mainAccountsEntity?.accountHolderSurname || '');
  const [middleName, setMiddleName] = useState<string>(mainAccountsEntity?.middle_name || '');
  const [email, setEmail] = useState<string>(mainAccountsEntity?.accountHolderEmail || '');
  const [phone, setPhone] = useState<string>(mainAccountsEntity?.accountHolderMobileNumber || '');
  const [maritalstatus, setMaritalstatus] = useState<string>(mainAccountsEntity?.accountHolderMaritalStatus || '');
  const [dependents, setdependents] = useState<string>(mainAccountsEntity?.accountHolderDependents || '');
  const [AccountType, setAccountType] = useState<string>(mainAccountsEntity?.accountHolderType || '');
  const [addressLine1, setAddressLine1] = useState<string>(mainAccountsEntity?.addressLine1 || '');
  const [addressLine2, setAddressLine2] = useState<string>(mainAccountsEntity?.addressLine2 || '');
  const [townOrCity, setTownorcity] = useState<string>(mainAccountsEntity?.townOrCity || '');
  const [state_or_province, setState_or_province] = useState<string>(mainAccountsEntity?.state_or_province );
  const [postcode, setPostcode] = useState<string>(mainAccountsEntity?.postcode || '');
  const [ppsNumber, setPpsnumber] = useState<string>(mainAccountsEntity?.ppsNumber || '');
  const [passport_number, setpassport_number] = useState<string>(mainAccountsEntity?.passport_number || '');
  const [country, setCountry] = useState<any>("Ireland");

  const [issued_country, setIssued_country] = useState<any>(mainAccountsEntity?.countryList?.find((data) => data.name === 'Ireland') || {});
  const [passport_expiry_date, setPassport_expiry_date] = useState<string>(mainAccountsEntity?.passport_expiry_date || '');
  const [countryOfBirth, setCountryOfBirth] = useState<any>(mainAccountsEntity?.countryList?.find((data) => data.name === 'Ireland') || {});
  const [citizenship, setCountryOfCitizenship] = useState<any>(mainAccountsEntity?.countryList?.find((data) => data.name === 'Ireland') || {});

  const [istaxresident, setIstaxresident] = useState<boolean>(mainAccountsEntity?.taxResidentInIreland || false);

  const [othertaxresidence, setOthertaxresidence] = useState<string>(mainAccountsEntity?.othertaxresidence || '');
  const [employment_status, setEmployment_status] = useState<string>(mainAccountsEntity?.employment_status || 'EMPLOYED');
  const [occupation, setOccupation] = useState<string>(mainAccountsEntity?.occupation || '');
  const [employerName, setEmployerName] = useState<string>(mainAccountsEntity?.employerName || '');
  const [investmentAmount, setInvestmentAmount] = useState<string>(mainAccountsEntity?.investmentAmount || '');
  const [employerBusiness, setEmployerBusiness] = useState<string>(mainAccountsEntity?.employerBusiness || '');
  const [isapubliclylistedcompany, setIsapubliclylistedcompany] = useState<string>("false" || mainAccountsEntity?.isapubliclylistedcompany);
  const [employerCompany, setEmployerCompany] = useState<string>(mainAccountsEntity?.employerCompany || '');
  const [uploadedFile, setUploadedFile] = useState<string>(mainAccountsEntity?.ppsNumberDoc || '');
  const [uploadedPassport, setUploadedPassport] = useState<string>(mainAccountsEntity?.passportDoc || '');
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({});
  const [ppsError, setPpsError] = useState<string | null>(null);
  const [pendingTaskAccountId, setPendingTaskAccountId] = useState([])
  const [stateList, setStateList] = useState<string[]>([]);

  const findCountryNameByKey = (key: string | null | undefined): string => {
    const countryName: any = countryList.find((obj: any) => obj.name === key);
    return countryName ? countryName.name : '';
  };

  const findStateNameByKey = (key: string | null | undefined): string => {
    const stateOrProvince: any = stateList.find((obj: any) => obj.name === key);
    return stateOrProvince ? stateOrProvince.name : '';
  };

  const initialCountryName = mainAccountsEntity?.country
    ? findCountryNameByKey(mainAccountsEntity.country)
    : '';
  const [employerAddress, setEmployerAddress] = useState({
    street: mainAccountsEntity?.employerAddress?.street,
    city: mainAccountsEntity?.employerAddress?.city,
    state: mainAccountsEntity?.employerAddress?.state  ,
    country: mainAccountsEntity?.employerAddress?.country || 'Ireland',
    postal_code: mainAccountsEntity?.employerAddress?.postal_code

  });
  // const [country, setCountry] = useState<string>(initialCountryName);

  interface IValidationErrors {
    [key: string]: string;
  }

  useImperativeHandle(ref, () => ({
    syncPrimaryDetailsWithServer
  }));

  useEffect(() => {
    dispatch(getPendingTask({}))
      .then((response: any) => {
        setPendingTaskAccountId(response?.payload?.data?.pending_accounts)
      })
  }, [])
  useEffect(() => {
    dispatch(getState()).then((res) => {
      if (res.payload) {
        const stateData = res.payload.map((item: any) => item);
        setStateList(stateData);
      }
    });

  }, [dispatch]);

  const accessToken = localStorage.getItem('jhi-authenticationToken');
  useEffect(() => {

    if (accessToken) {
      dispatch(getCountryList()).then((res) => {
        dispatch(getCustomerDetails());
        if (res.payload) {
          const countryData = res.payload.map((item: any) => item);
          setCountryList(countryData);
        }
      });
    }

  }, [dispatch]);

  useEffect(() => {
    if (customerDetails) {
      setAccountHolderName(customerDetails?.firstName || '');
      setLastName(customerDetails?.lastName || mainAccountsEntity?.accountHolderSurname || '');
      setEmail(customerDetails?.email || '');
      setPhone(customerDetails?.phone || '');

      const updatedProperties = {
        accountHolderTitle: customerDetails?.accountHolderTitle ? customerDetails.accountHolderTitle : 'Mr',
        accountHolderSurname: customerDetails?.lastName,
        accountHolderEmail: customerDetails?.email,
        state_or_province: customerDetails?.stateOrProvince  ,
        country: customerDetails?.country ? customerDetails.country : 'Ireland',
        issued_country: customerDetails?.issued_country ? customerDetails.issued_country : 'Ireland',
        accountHolderDependents: customerDetails?.accountHolderDependents ? customerDetails.accountHolderDependents : 1,
        employment_status: customerDetails?.employmentStatus ||mainAccountsEntity.employment_status || 'EMPLOYED',
        employerAddress: {
          ...mainAccountsEntity.employerAddress,
          country: "Ireland",
          state: ''
        },
        birth_info: {
          countryOfBirth: customerDetails?.countryOfBirth || 'Ireland',
        },
        taxResidentInIreland: mainAccountsEntity?.taxResidentInIreland || false,

      };

      dispatch(updateEntityProperty(updatedProperties));
    }
  }, [customerDetails]);

  const syncPrimaryDetailsWithServer = (): Promise<any> => {

    const _errors: IValidationErrors = {};

    if (!investmentAmount) {
      _errors.investmentAmount = 'This field is mandatory';
    }
    if (!addressLine1) {
      _errors.addressLine1 = 'This field is mandatory';
    }
    if (!townOrCity) {
      _errors.townOrCity = 'This field is mandatory';
    }
    if (!postcode) {
      _errors.postcode = 'This field is mandatory';
    }
    if (!ppsNumber) {
      _errors.ppsNumber = 'This field is mandatory';
    }
    if (!uploadedFile) {
      _errors.uploadedFile = 'This field is mandatory';
    }
    if (!passport_number) {
      _errors.passport_number = 'This field is mandatory';
    }
    if (!uploadedPassport) {
      _errors.uploadedPassport = 'This field is mandatory';
    }
    if (!citizenship) {
      _errors.citizenship = 'This field is mandatory';
    }
    if (!state_or_province){
      _errors.state_or_province = 'This field is mandatory';
    }
    if (!passport_expiry_date) {
      _errors.passport_expiry_date = 'This field is mandatory';
    }
    if (istaxresident === true && !othertaxresidence) {
      _errors.othertaxresidence = 'This field is mandatory';
    }
    if (employment_status === "EMPLOYED") {
      if (!occupation) {
        _errors.occupation = 'This field is mandatory';
      }
      if (!employerName) {
        _errors.employerName = 'This field is mandatory';
      }
      if (!employerBusiness) {
        _errors.employerBusiness = 'This field is mandatory';
      }
      if (!employerAddress?.city) {
        _errors.city = 'This field is mandatory';
      }
      if (!employerAddress?.state){
        _errors.state ='This field is mandatory';
      }
      if (!employerAddress?.postal_code) {
        _errors.postal_code = 'This field is mandatory';
      }
      if (!employerAddress?.street) {
        _errors.street = 'This field is mandatory';
      }
    }
    if (Object.keys(_errors).length > 0) {
      setValidationErrors(_errors);
      setToastMessage((prev) => 'Please fill all the mandatory fields');
      setToastType('error');
      setToastVisible(true);
      return null;
    }
    setValidationErrors({});
    return dispatch(updateEntity({ ...mainAccountsEntity, last_level: 3 }));
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    const { name, value, id } = e.target;
    const lcl_errors: { [key: string]: string } = {};

    if (name === 'accountHolderTitle') {
      setNameTitle(value);
    } else if (name === 'middle_name') {
      setMiddleName(value);
    } else if (name === 'accountHolderName') {
      setAccountHolderName(value);
    } else if (name === 'accountHolderSurName') {
      setLastName(value);
    } else if (name === 'accountHolderEmail') {
      setEmail(value);
    } else if (name === 'accountHolderMaritalStatus') {
      setMaritalstatus(value);
    } else if (name === 'accountHolderDependents') {
      setdependents(value);
    } else if (name === 'addressLine1') {
      setAddressLine1(value);
    } else if (name === 'addressLine2') {
      setAddressLine2(value);
    } else if (name === 'townOrCity') {
      setTownorcity(value);
    } else if (name === 'state_or_province') {
      setState_or_province(value);
    } else if (name === 'country') {
      setCountry(value);
    } else if (name === 'issued_country') {
      setIssued_country(value);
    } else if (name === 'postcode') {
      setPostcode(value);
    } else if (name === 'passport_number') {
      setpassport_number(value);
    } else if (name === 'countryOfBirth') {
      setCountryOfBirth(value);
    } else if (name === 'countryOfCitizenship') {
      setCountryOfCitizenship(value);
    } else if (name === 'othertaxresidence') {
      setOthertaxresidence(value);
    }
    else if (name === 'occupation') {
      setOccupation(value);
    } else if (name === 'employment_status') {
      setEmployment_status(value);
    } else if (name === 'employerName') {
      setEmployerName(value);
    } else if (name === 'employerBusiness') {
      setEmployerBusiness(value);
    }
    else if (name === 'isapubliclylistedcompany') {
      setIsapubliclylistedcompany(value);
    }
    else if (name === 'employerCompany') {
      setEmployerCompany(value);
    }
    if (value) {
      clearValidationError(name);
    }
    dispatch(updateEntityProperty({
      [name]: value
    }));

  };

  const handleChangePhone = (e: any) => {
    const lcl_phone = e.target.value;
    dispatch(updateEntityProperty({ accountHolderMobileNumber: lcl_phone }));
  }

  const clearValidationError = (field: string) => {
    setValidationErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleCountryIssued = (e: any) => {
    const { name, value } = e.target;
    setEmployerAddress(prevEmployerAddress => ({
      ...prevEmployerAddress,
      [name]: value
    }));

    if (name === 'postal_code') {

      dispatch(updateEntityProperty({ employerAddress: { ...mainAccountsEntity.employerAddress, ['postal_code']: value } }));
    } else {
      dispatch(updateEntityProperty({ employerAddress: { ...mainAccountsEntity.employerAddress, [name]: value } }));
    }
    if (value) {
      clearValidationError(name);
    }

  }
  const handlePassportExpiry = (e: any) => {
    const lcl_passport_expiry_date = e.target.value;
    setPassport_expiry_date(lcl_passport_expiry_date)
    clearValidationError('passport_expiry_date');
    dispatch(updateEntityProperty({ passport_expiry_date: lcl_passport_expiry_date }));

  }
  useEffect(() => {
    setMinDate(getFifteenDaysFromToday());
  }, []);
  const getFifteenDaysFromToday = () => {
    const today = new Date();
    today.setDate(today.getDate() + 15);
    return today.toISOString().split('T')[0];
  };
  const handleCountryOfBirth = (e: any) => {
    setCountryOfBirth(e.target.value)
    dispatch(updateEntityProperty({
      birth_info: {
        countryOfBirth: e.target.value
      }
    }));
  };

  const handleCountryOfCitizenship = (e: any) => {
    setCountryOfCitizenship(e.target.value)
    dispatch(updateEntityProperty({
      citizenship: e.target.value
    }));
  };

  // useEffect(() => {
  //   // Set initial state from session storage or default to false
  //   const storedValue = sessionStorage.getItem('taxResidentInIreland');
  //   setIstaxresident(storedValue === 'true');
  // }, []);

  const handleTaxResidentChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = e.target;

    if (name === "taxResidentInIreland") {
      sessionStorage.setItem('taxResidentInIreland', JSON.stringify(checked));
      dispatch(updateEntityProperty({ ['taxResidentInIreland']: checked }));
      setIstaxresident(checked);
    }
  };
  const handleEmploymentStatusChange = (e: any) => {
    const lcl_employment_status = e.target.value;
    setEmployment_status(employment_status)
    dispatch(updateEntityProperty({ employment_status: lcl_employment_status }));
  }

  const handleInvestmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputVal = e.target.value;
    const numericValue = parseFloat(inputVal.replace(/[^\d.]/g, ''));

    if (!isNaN(numericValue)) {
      setInvestmentAmount(inputVal);

      if (numericValue >= 50000) {
        setInvestError('');
        clearValidationError('investmentAmount');
      } else {
        setInvestError(numericValue ? 'Minimum value should be €50,000' : 'This field is mandatory');
        if (numericValue) {
          clearValidationError('investmentAmount');
        }
      }

      dispatch(updateEntityProperty({ investmentAmount: numericValue.toString() }));
    } else {
      setInvestmentAmount('');
      setInvestError('Invalid input');
    }
  };

  const formatCurrency = (amount: string | number | null | undefined): string => {
    if (amount === '') {
      return '';
    }
    const amountStr = amount.toString();

    const numericAmount = parseFloat(amountStr.replace(/[^\d.]/g, ''));
    if (isNaN(numericAmount)) {
      return '';
    }

    return numericAmount.toLocaleString('en-EN', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
    });
  };

  //
  const handleFileUpload = (file: any, docType: string) => {
    if (!accountsEntity && !pendingTaskAccountId?.[0]?.accountId) {
      return;

    }
    setIsUploadingOne(true);
    dispatch(
      uploadDocument({
        accountId: accountsEntity || pendingTaskAccountId?.[0]?.accountId,
        file,
        docType,
      })
    )
      .then((response: any) => {
        if (response.payload.data) {
          setUploadedFile(response.payload.data);

          // Dispatch the updated entity property
          dispatch(updateEntityProperty({
            ppsNumberDoc: response.payload.data
          }));
          setToastMessage((prev) => 'Uploaded successfully');
          setToastType('success');
          setToastVisible(true);
          setIsUploadingOne(false);

        } else {
          console.error('Response payload is undefined');

        }
      })
      .catch((error) => {
        setIsUploadingOne(false);
        console.error(`Error uploading ${docType}:`, error);
      });



  };
  const PPS_REGEX = /^\d{7}[A-Z]{1,2}$/;
  const handlePpsNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPpsnumber(value);

    if (PPS_REGEX.test(value)) {
      setPpsError('');
    } else {
      setPpsError('Invalid PPS number format. Expected format: 7 digits followed by 1 or 2 uppercase letters.');
    }
    dispatch(updateEntityProperty({ ppsNumber: value }));
    clearValidationError('ppsNumber');
  };
  //end
  const handleDeleteFile = () => {
    setUploadedFile(null);
  };

  const handlePassportFileUploaded = (file: any, docType: string) => {
    if (!accountsEntity && !pendingTaskAccountId?.[0].accountId) {


      return;

    }
    setIsUploadingTwo(true);
    dispatch(
      uploadDocument({
        accountId: accountsEntity || pendingTaskAccountId?.[0]?.accountId,
        file,
        docType,
      })
    )
      .then((response: any) => {
        if (response.payload.data) {
          setUploadedPassport(response.payload.data);
          const updatedJointDetails = { ...mainAccountsEntity, passportDoc: response.payload.data };


          dispatch(updateEntityProperty({
            passportDoc: response.payload.data,
          }));
          setToastMessage((prev) => 'Uploaded successfully');
          setToastType('success');
          setToastVisible(true);
          setIsUploadingTwo(false);

        } else {
          console.error('Response payload is undefined');
        }
      })
      .catch((error) => {
        setIsUploadingTwo(false);
        console.error(`Error uploading ${docType}:`, error);
      });
  }

  const handleDeletePassportFile = () => {
    setUploadedPassport(null);
  };

  const handleKeyDown = (event: any) => {
    const allowedKeys = /[0-9+\b]/;
    if (!allowedKeys.test(event.key) && event.keyCode !== 8) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    dispatch(getCountriesList());
  }, [dispatch]);
  const [minDate, setMinDate] = useState('');

  return (
    <div>
      <CommonToast
        visible={toastVisible}
        onClose={() => setToastVisible(false)}
        title={toastType === 'success' ? 'Success' : 'Error'}
        description={toastMessage}
        type={toastType}
        isAutoClose={true}


      />
      <Row>
        <Col md="12" className="form-firstrow-account-head-custom pt-3 pb-3">
          Primary Applicant Details
        </Col>
        <Row>
          <Col md="4">
            <FormGroup>
              <Label className="label-account-class text-left text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Title</span>
              </Label>

              <Input
                type="select"
                name="accountHolderTitle"
                id="accountHolderTitle"
                className="textfield-style"
                value={nameTitle}
                defaultValue={mainAccountsEntity?.accountHolderTitle}
                onChange={e => handleValueChange(e)}
              >
                <option>Mr</option>
                <option>Mrs</option>
                <option>Ms</option>
                <option>Dr</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md="4" className="">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>First Name </span>{' '}
              </Label>
              <Input
                className="textfield-style-read-only"
                type="text"
                name="accountHolderName"
                id="accountHolderName"
                required
                value={accountHolderName}
                onChange={e => handleValueChange(e)}
                placeholder="enter your name"
                readOnly
              />

              {validationErrors && accountHolderName === '' && <div className="text-danger">{validationErrors?.name}</div>}
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Last Name</span>
              </Label>

              <Input
                type="text"
                name="accountHolderSurName"
                id="accountHolderSurName"
                value={lastName}
                required
                onChange={e => handleValueChange(e)}
                className="textfield-style-read-only"
                readOnly
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}></span>
                <span>Middle Name</span>
              </Label>
              <Input
                className="textfield-style"
                type="text"
                name="middle_name"
                id="middle_name"
                required
                value={middleName}
                defaultValue={mainAccountsEntity?.middle_name}
                onChange={e => handleValueChange(e)}
                placeholder="enter your name"
              />

              <span className="text-black">Please provide your middle name if it is included in your passport</span>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Your Email</span>
              </Label>
              <Input
                className="textfield-style-read-only"
                type="email"
                name="accountHolderEmail"
                required
                id="accountHolderEmail"
                value={email}
                // defaultValue={mainAccountsEntity?.accountHolderEmail}
                onChange={e => handleValueChange(e)}
                placeholder="enter your email"
                readOnly
              />
              {validationErrors && email === '' && <div className="text-danger">{validationErrors?.email}</div>}
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Phone Number</span>
              </Label>
              <Input
                className="textfield-style-read-only"
                type="tel"
                name="accountHolderMobileNumber"
                id="phonenumber"
                value={phone}
                // defaultValue={mainAccountsEntity?.accountHolderMobileNumber}
                onChange={e => handleChangePhone(e)}
                placeholder="enter your phonenumber"
                readOnly
              />

              {validationErrors && phone === '' && <div className="text-danger">{validationErrors.phone}</div>}
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Marital Status</span>
              </Label>

              <Input
                type="select"
                name="accountHolderMaritalStatus"
                id="accountHolderMaritalStatus"
                className="textfield-style"
                value={maritalstatus}
                defaultValue={mainAccountsEntity?.accountHolderMaritalStatus}
                onChange={e => handleValueChange(e)}
              >
                <option value={'Married'}>Married</option>
                <option value={'Single'}>Single</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Dependents</span>
              </Label>

              <Input
                type="select"
                name="accountHolderDependents"
                id="accountHolderDependents"
                className="textfield-style"
                value={String(dependents)}
                defaultValue={mainAccountsEntity?.accountHolderDependents}
                onChange={e => handleValueChange(e)}
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>Account Type
              </Label>
              <Input
                type="text"
                name="accountHolderType"
                id="accounttype"
                className="textfield-style-read-only"
                value={mainAccountsEntity?.accountHolderType}
                onChange={handleValueChange}
                readOnly
              ></Input>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>Investment Amount
              </Label>
              <Input
                type="text"
                name="investmentAmount"
                id="investmentAmount"
                placeholder="Enter your amount"
                className="textfield-style"
                inputMode="numeric"

                value={formatCurrency(investmentAmount)}
                defaultValue={mainAccountsEntity?.investAmount}
                onChange={e => handleInvestmentChange(e)}
              />
              {investError && <div className="text-danger">{investError}</div>}
              {validationErrors.investmentAmount && <div className="text-danger">{validationErrors.investmentAmount}</div>}            </FormGroup>
          </Col>
        </Row>
        <Row>
          <FormGroup>
            <Col md="12" className="under-border-div mt-3 mb-3"></Col>
          </FormGroup>
        </Row>

        <Row>
          <Col md="4  ">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>House Street Address
              </Label>
              <Input
                type="text"
                name="addressLine1"
                id="addressLine1"
                className="textfield-style"
                value={addressLine1}
                defaultValue={mainAccountsEntity?.addressLine1}
                onChange={e => handleValueChange(e)}
              />
              {validationErrors.addressLine1 && <div className="text-danger">{validationErrors.addressLine1}</div>}{' '}
            </FormGroup>
          </Col>{' '}
          <Col md="4  ">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}></span>Address Line 2
              </Label>

              <Input
                type="text"
                name="addressLine2"
                id="addressLine2"
                className="textfield-style"
                value={addressLine2}
                defaultValue={mainAccountsEntity?.addressLine2}
                onChange={e => handleValueChange(e)}
              />
            </FormGroup>
          </Col>{' '}

          <Col md="4">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Town / City</span>
              </Label>

              <Input
                type="text"
                name="townOrCity"
                id="townOrCity"
                className="textfield-style"
                value={townOrCity}
                defaultValue={mainAccountsEntity?.townOrCity}
                onChange={e => handleValueChange(e)}
              />
              {validationErrors.townOrCity && <div className="text-danger">{validationErrors.townOrCity}</div>}
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                County
              </Label>

              <Input
                type="select"
                name="state_or_province"
                id="state_or_province"
                className="textfield-style"
                value={state_or_province}
                defaultValue={mainAccountsEntity?.state_or_province}
                onChange={e => handleValueChange(e)}
              >
                <> <option value=""></option></>
                {stateList && stateList.map((item: any, index) => (
                  <option key={index} value={item?.name}>{item?.name}</option>
                ))}
              </Input>
              {validationErrors.state_or_province && <div className="text-danger">{validationErrors.state_or_province}</div>}  
            </FormGroup>
          </Col>
          <Col md="4">
            {/* <FormGroup> */}
            <span style={{ color: 'red', fontSize: 20 }}>*</span>
            <Label className="label-account-class">Country</Label>
            <Input
              type="text"
              name="country"
              id="country"
              className="textfield-style"
              value={country}
              // defaultValue={country}
              onChange={handleValueChange}
              readOnly
            />




            {/* </FormGroup> */}
          </Col>
          <Col md="4">
            <FormGroup>
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
              <Label className="label-account-class">Post Code</Label>
              <Input
                type="text"
                name="postcode"
                id="postcode"
                className="textfield-style"
                value={postcode}
                defaultValue={mainAccountsEntity?.postcode}
                onChange={e => handleValueChange(e)}
              />
              {validationErrors.postcode && <div className="text-danger">{validationErrors.postcode}</div>}{' '}
            </FormGroup>
          </Col>
          <Col md="6" className="py-3">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>PPS Number</span>
              </Label>
              <div className="pt-4 mt-2">
                <Input
                  type="text"
                  name="ppsNumber"
                  id="ppsNumber"
                  className="textfield-style"
                  value={ppsNumber}
                  defaultValue={mainAccountsEntity?.ppsNumber}
                  onChange={e => handlePpsNumberChange(e)}
                />
                {ppsError && <div className="error-message" style={{ color: 'red' }}>{ppsError}</div>}

                {validationErrors.ppsNumber && <div className="text-danger">{validationErrors.ppsNumber}</div>}{' '}
              </div>
            </FormGroup>
          </Col>
          <Col md="6" className="py-3">
            <Row>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Proof of Address Upload</span>
              </Label>

              <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {isUploadingOne ? (
                  <div style={{ width: '50px' }}>
                    <Lottie animationData={animationData} loop autoplay />
                    <span style={{ color: '#607980' }}>Uploading...</span>
                  </div>
                ) : (
                  <>
                    {uploadedFile ? (
                      <div className="textfield-style-upload">
                        <ul style={{ listStyleType: 'none' }}>
                          <li className="text-light iconxs">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <span className="text-danger cursor-pointer ms-2 strong gap-5">
                                <FileEarmarkMedicalFill className="text-primary" style={{ fontSize: '40px' }} />
                                <span> &nbsp;</span>
                                <span className="text-primary"> {'PPC'} </span>
                              </span>
                              <div className="trash-btn" onClick={handleDeleteFile} style={{ cursor: 'pointer' }}>
                                <Trash className="trash-btn" />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <div>
                        <span className="text-black">
                          Please upload a recent bank statement or utility bill. This should match the above address details
                        </span>

                        <DropZone onFileUploaded={handleFileUpload} docType={'PPC'} />
                        {validationErrors.uploadedFile && <div className="text-danger">{validationErrors.uploadedFile}</div>}
                      </div>
                    )}
                  </>
                )}
              </div>
            </Row>
          </Col>
          <Col md="6 " className="py-3">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Passport Number</span>
              </Label>
              <Input
                type="text"
                name="passport_number"
                id="passport_number"
                className="textfield-style"
                value={passport_number}
                defaultValue={mainAccountsEntity?.passport_number}
                onChange={e => handleValueChange(e)}
              />
              {validationErrors.passport_number && <div className="text-danger">{validationErrors.passport_number}</div>}{' '}
            </FormGroup>
          </Col>
          <Col md="6" className="py-3">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>Country Issued
              </Label>
              <Input
                type="select"
                name="issued_country"
                id="issued_country"
                className="textfield-style"
                value={issued_country.name}
                onChange={handleValueChange}
              >
                <> <option value={issued_country.name}>{issued_country.name || findCountryNameByKey("Ireland")}</option></>
                {countryList && countryList?.filter((obj: any) => obj.name !== 'Ireland')?.map((item: any, index) => (
                  <option key={index} value={item.name}>{item.name}</option>
                ))}
              </Input>


              {validationErrors && !issued_country && <div className="text-danger">{validationErrors.issued_country}</div>}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Passport Expiry Date</span>
              </Label>

              <Form className="pt-3">
                <ValidatedField
                  name="PassportExpiry"
                  required
                  className="datefield-expiry-style"
                  type="date"
                  data-cy="passport expiry date"
                  value={passport_expiry_date}
                  onChange={(e) => handlePassportExpiry(e)}
                  defaultValue={mainAccountsEntity?.passport_expiry_date}
                  style={{ width: '100%', padding: '3px 12px', backgroundImage: 'none' }}
                  min={minDate}
                  onKeyDown={(e) => e.preventDefault()}
                />
                <span className="text-black">
                  Please select from calender
                </span>
                {validationErrors.passport_expiry_date && <div className="text-danger">{validationErrors.passport_expiry_date}</div>}{' '}
              </Form>
            </FormGroup>
          </Col>
          <Col md="6">
            <Row>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Passport Document Upload</span>
              </Label>

              <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {isUploadingTwo ? (
                  <div style={{ width: '50px' }}>
                    <Lottie animationData={animationData} loop autoplay />
                    <span style={{ color: '#607980' }}>Uploading...</span>
                  </div>
                ) : (
                  <>
                    {uploadedPassport ? (
                      <div className=" textfield-style-upload ">
                        <ul style={{ listStyleType: 'none' }}>
                          <li className="text-light iconxs">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <span className="text-danger cursor-pointer ms-2 strong gap-5">
                                <FileEarmarkMedicalFill className="text-primary" style={{ fontSize: '40px' }} /> <span> &nbsp;</span>{' '}
                                <span className="text-primary">{'PASSPORT'}</span>
                              </span>
                              <div className="trash-btn" onClick={handleDeletePassportFile} style={{ cursor: 'pointer' }}>
                                <Trash className="trash-btn" />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <div>
                        <span className="text-black">
                          Please upload a recent passport document statement. This should include the main page and the signature page, which must be signed.
                        </span>
                        <DropZone onFileUploaded={handlePassportFileUploaded} docType={'PASSPORT'} />
                        {validationErrors.uploadedPassport && <div className="text-danger">{validationErrors.uploadedPassport}</div>}{' '}
                      </div>
                    )}
                  </>
                )}
              </div>
            </Row>
          </Col>

          <Col md="6">
            <FormGroup>
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
              <Label className="label-account-class">Country of Birth</Label>
              <Input
                type="select"
                name="countryOfBirth"
                id="countryOfBirth"
                className="textfield-style-read-only"
                value={countryOfBirth.name} // Set value instead of defaultValue
                onChange={e => handleCountryOfBirth(e)}
              >
                <> <option value={countryOfBirth.name}>{countryOfBirth.name || findCountryNameByKey("Ireland")}</option></>
                {countryList && countryList?.filter((obj: any) => obj.name !== 'Ireland')?.map((item: any, index) => (
                  <option key={index} value={item.name}>{item.name}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
              <Label className="label-account-class">Country of Citizenship</Label>
              <Input
                type="select"
                name="countryOfCitizenship"
                id="countryOfCitizenship"
                className="textfield-style-read-only"
                value={citizenship.name} // Set value instead of defaultValue
                onChange={e => handleCountryOfCitizenship(e)}
              >
                <> <option value={citizenship.name}>{citizenship.name || findCountryNameByKey("Ireland")}</option></>
                {countryList && countryList?.filter((obj: any) => obj.name !== 'Ireland')?.map((item: any, index) => (
                  <option key={index} value={item.name}>{item.name}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>

          <Row>
            <FormGroup>
              <Col md="12" className="under-border-div mt-3 mb-3"> </Col>
            </FormGroup>
            <FormGroup>

              <Label className="label-account-class">Your tax status</Label></FormGroup>
          </Row>
          <Row>
            <Col>
              <Col md="12 py-2 ml-4" style={{ marginLeft: "5px" }}>
                <FormGroup check>
                  <Label check className="label-account-class">

                    <Input
                      type="checkbox"
                      name="taxResidentInIreland"
                      id="taxResidentInIreland"
                      className="select-box"
                      checked={istaxresident}
                      // defaultChecked={mainAccountsEntity?.taxResidentInIreland}
                      defaultValue={mainAccountsEntity?.taxResidentInIreland}
                      onChange={handleTaxResidentChange}
                    />
                    Are you also a tax resident in another country?
                  </Label>
                </FormGroup>
              </Col>
              {istaxresident === true ? (
                <Col md="12">
                  <FormGroup>
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>
                    <Label className="label-account-class">Other Tax Residence Details</Label>

                    <Input
                      type="text"
                      name="othertaxresidence"
                      id="othertaxresidence"
                      className="textfield-style"
                      value={othertaxresidence}
                      defaultValue={mainAccountsEntity?.othertaxresidence}
                      onChange={handleValueChange}
                    />
                    <span className="text-black">
                      Please list any other countries where you are tax resident, including your Tax Identification Number for that country e.g. United Kingdom, NiNo
                    </span>
                    {validationErrors?.othertaxresidence && <div className="text-danger">{validationErrors?.othertaxresidence}</div>}

                  </FormGroup>
                </Col>
              ) : null}
            </Col>
          </Row>

          <Row>
            <FormGroup>
              <Col md="12" className="under-border-div mt-3 mb-3"></Col>
            </FormGroup>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <span style={{ color: 'red', fontSize: 20 }}>*</span>

                <Label className="label-account-class">Employment Status</Label>
                <Input
                  type="select"
                  name="employment_status"
                  id="employment_status"
                  className="textfield-style"
                  value={employment_status}
                  defaultValue={mainAccountsEntity?.employment_status}
                  onChange={handleValueChange}
                >

                  <option value={"EMPLOYED"}>Employed</option>
                  <option value={"SELFEMPLOYED"}>Self Employed</option>
                  <option value={"UNEMPLOYED"}>Unemployed</option>
                  <option value={"RETIRED"}>Retired</option>
                  <option value={"STUDENT"}>Student</option>
                  <option value={"HOMEMAKER"}>Home Maker</option>

                </Input>
              </FormGroup>
            </Col>
            {employment_status === 'EMPLOYED' || employment_status === 'SELFEMPLOYED' ? (
              <>
                <Col md="6">
                  <FormGroup>
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>

                    <Label className="label-account-class">Occupation</Label>
                    <Input
                      type="text"
                      name="occupation"
                      id="occupation"
                      value={occupation}
                      defaultValue={mainAccountsEntity?.occupation}
                      className="textfield-style"
                      onChange={e => handleValueChange(e)}
                    />
                    {validationErrors.occupation && <div className="text-danger">{validationErrors.occupation}</div>}{' '}

                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>

                    <Label className="label-account-class">Employer Name</Label>
                    <Input
                      type="text"
                      name="employerName"
                      id="employerName"
                      value={employerName}
                      defaultValue={mainAccountsEntity?.employerName}
                      className="textfield-style"
                      onChange={e => handleValueChange(e)}
                    />
                    {validationErrors.employerName && <div className="text-danger">{validationErrors.employerName}</div>}{' '}

                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>

                    <Label className="label-account-class">Employer Business</Label>
                    <Input
                      type="text"
                      name="employerBusiness"
                      value={employerBusiness}
                      defaultValue={mainAccountsEntity?.employerBusiness}
                      id="employerBusiness"
                      className="textfield-style"
                      onChange={e => handleValueChange(e)}
                    />
                    {validationErrors.employerBusiness && <div className="text-danger">{validationErrors.employerBusiness}</div>}{' '}

                  </FormGroup>
                </Col>
                <Col md="12" className="pt-2">
                  <FormGroup>
                    <Label className="label-account-class">Employer Address</Label>
                  </FormGroup>
                </Col>
                <Col md="6" className="pt-2">
                  <FormGroup>
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>

                    <Label className="label-account-class">Street</Label>
                    <Input
                      type="text"
                      name="street"
                      // value={employerAddressStreet}
                      defaultValue={employerAddress?.street}
                      id="street"
                      className="textfield-style"
                      onChange={e => handleCountryIssued(e)}
                    />
                    {validationErrors.street && <div className="text-danger">{validationErrors.street}</div>}{' '}

                  </FormGroup>
                </Col>

                <Col md="6" className="pt-2">
                  <FormGroup>
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>

                    <Label className="label-account-class">City</Label>
                    <Input
                      type="text"
                      name="city"
                      // value={employerAddressCity}
                      defaultValue={employerAddress?.city}
                      id="city"
                      className="textfield-style"
                      onChange={e => handleCountryIssued(e)}
                    />
                    {validationErrors.city && <div className="text-danger">{validationErrors.city}</div>}{' '}

                  </FormGroup>
                </Col>
                <Col md="6" className="pt-2">
                  <FormGroup>
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>

                    <Label className="label-account-class">County</Label>
                    <Input
                      type="select"
                      name="state"
                      defaultValue={mainAccountsEntity?.employerAddress?.state}
                      id="state"
                      value={employerAddress.state}
                      className="textfield-style"
                      onChange={e => handleCountryIssued(e)}
                    >
                      {/* Render the mainAccountsEntity.state_or_province as an option if it exists */}
                      {/* {mainAccountsEntity?.state_or_province && ( */}
                        <option value="">
                          {/* {mainAccountsEntity.state_or_province} */}
                        </option>
                      {/* )} */}
                      {/* Map through stateList and render options */}
                      {stateList && stateList.map((item: any, index) => (
                        <option key={index} value={item.name}>{item.name}</option>
                      ))}
                    </Input>

                    {validationErrors.state && <div className="text-danger">{validationErrors.state}</div>}{' '}

                  </FormGroup>
                </Col>
                <Col md="6" className="pt-2">
                  <FormGroup>
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>
                    <Label className="label-account-class">Country</Label>
                    <Input
                      type="select"
                      name="country"
                      defaultValue={employerAddress?.country || "Ireland"}
                      id="country"
                      className="textfield-style"
                      onChange={e => handleCountryIssued(e)}
                    >
                      <>
                        <> <option value={country.name}>{issued_country.name || findCountryNameByKey("Ireland")}</option></>
                        {countryList && countryList?.filter((obj: any) => obj.name !== 'Ireland')?.map((item: any, index) => (
                          <option key={index} value={item.name}>{item.name}</option>
                        ))}
                      </>
                    </Input>

                    {validationErrors.employerAddressCountry && <div className="text-danger">{validationErrors.employerAddressCountry}</div>}{' '}

                  </FormGroup>
                </Col>
                <Col md="6" className="pt-2">
                  <FormGroup>
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>

                    <Label className="label-account-class">Post Code</Label>
                    <Input
                      type="text"
                      name="postal_code"
                      // value={employerAddressPostal_code}
                      defaultValue={employerAddress?.postal_code}
                      id="postal_code"
                      className="textfield-style"
                      onChange={e => handleCountryIssued(e)}
                    />
                    {validationErrors.postal_code && <div className="text-danger">{validationErrors.postal_code}</div>}{' '}

                  </FormGroup>
                </Col>

                <Col md="6" className="pt-2">
                  <FormGroup>
                    <Label className="label-account-class">
                      <span style={{ color: 'red', fontSize: 20 }}>*</span>
                      <span>Is the Employer a Publicly Listed Company?</span>{' '}
                    </Label>
                    <Input
                      type="select"
                      name="isapubliclylistedcompany"
                      value={isapubliclylistedcompany}
                      defaultValue={mainAccountsEntity?.isapubliclylistedcompany}
                      id="isapubliclylistedcompany"
                      className="textfield-style"
                      onChange={e => handleValueChange(e)}
                    >
                      {validationErrors.isapubliclylistedcompany && <div className="text-danger">{validationErrors.isapubliclylistedcompany}</div>}{' '}

                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </Input>
                  </FormGroup>
                  {mainAccountsEntity?.isapubliclylistedcompany === "true" ? (
                    <FormGroup>
                      <Label className="label-account-class">
                        <span style={{ color: 'red', fontSize: 20 }}>*</span>
                        <span> Employer Company Stock Ticker Symbol</span>{' '}
                      </Label>
                      <Input
                        type="text"
                        name="employerCompany"
                        id="employerCompany"
                        value={employerCompany}
                        defaultValue={mainAccountsEntity?.employerCompany}
                        className="textfield-style"
                        onChange={e => handleValueChange(e)}
                      />
                    </FormGroup>
                  ) : null}
                </Col>
              </>
            ) : null}
          </Row>
        </Row>
      </Row>
    </div>
  );
};
export default forwardRef(PrimaryApplication);
