import React, { useEffect, useState } from 'react';
import { HouseDoor } from 'react-bootstrap-icons';

import { NavItem, NavLink, NavbarBrand, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { NavLink as Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getCustomerDetails } from 'app/entities/customer/customer.reducer';



export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/logo-jhipster.png" alt="Logo" />
  </div>
);

// export const Brand = () => (
//   <NavbarBrand tag={Link} to="https://www.starlightwealth.ie" className="brand-logo">
//     <span className="brand-title d-flex">
//       <HouseDoor size={'35'} className="pe-2 p-1 " />
//       <span className="brand-heading-text">STARLIGHT WEALTH </span>
//     </span>
//   </NavbarBrand>
// );

export const Brand = () => (
  <NavbarBrand>
    <a href="https://www.starlightwealth.ie" target="_blank" rel="noopener noreferrer" className="brand-logo new-home-button-styles">
      <span className="brand-title d-flex">
        <HouseDoor size={'35'} className="pe-2 p-1" />
        <span className="brand-heading-text">STARLIGHT WEALTH</span>
      </span>
    </a>
  </NavbarBrand>
);
export const Client = ({ userName, userType }) => (
  <NavItem className=" right-text ms-4 ">
    {userType === 'ROLE_USER' ? (
      <>
        {userName ? (
          <NavLink tag={Link} to="/user-profile" className="align-items-center custom-button">
            <button className="client-portal-btn-style">Client Portal</button>
          </NavLink>
        ) : (
          <NavLink tag={Link} to="/signin" className=" align-items-center custom-button">
            <button className="client-portal-btn-style">Client Portal</button>
          </NavLink>
        )}
      </>
    ) : (
      <></>
    )}
  </NavItem>
);

export const Admin = ({ userName, userType }) => {

  return (
    <>
      <NavItem className=" right-text ms-3">

        {userType === 'ROLE_ADMIN' ? (
          <>
            {userName ? (
              <NavLink tag={Link} to="/admin-profile" className="align-items-center custom-button">
                <button className="client-portal-btn-style">Admin Portal</button>
              </NavLink>
            ) : (
              <NavLink tag={Link} to="/signin" className="align-items-center custom-button">
                <button className="client-portal-btn-style">Admin Portal</button>
              </NavLink>
            )}
          </>
        ) : (
          <></>
        )}
      </NavItem>
    </>
  );
}
// export const Broker = ({ userName, userType }) => (
//   <NavItem className="right-text  ms-3">
//     {userType === 'ROLE_USER' ? (
//       <>
//         {userName ? (
//           <NavLink tag={Link} to="/" className=" align-items-center custom-button">
//             <button className="broker-login-btn-style">Broker Login</button>
//           </NavLink>
//         ) : (
//           <NavLink tag={Link} to="/signin" className=" align-items-center custom-button">
//             <button className="broker-login-btn-style">Broker Login</button>
//           </NavLink>
//         )}
//       </>
//     ) : (
//       <></>
//     )}
//   </NavItem>
// );

export const OpenAccount = ({ userName, userType }) => {

  return (
    <NavItem className="right-text ms-3">

      {userType === 'ROLE_USER' ? (
        <>
          {userName ? (
            <NavLink tag={Link} to="/accounts" className="align-items-center custom-button">
              <button className="open-account-btn-style">Open Individual/Joint</button>
            </NavLink>
          ) : (
            <NavLink tag={Link} to="/signin" className=" align-items-center custom-button">
              <button className="open-account-btn-style">Open Individual/Joint</button>
            </NavLink>
          )}
        </>
      ) : (
        <></>
      )}
    </NavItem>
  )
};
export const OpenCompany = ({ userName, userType }) => (
  <NavItem className="right-text ms-3">
    {userType === 'ROLE_USER' ? (
      <>
        {userName ? (
          <NavLink tag={Link} to="/company" className=" align-items-center custom-button">
            <button className="open-account-btn-style">Open Company</button>
          </NavLink>
        ) : (
          <NavLink tag={Link} to="/signin" className=" align-items-center custom-button">
            <button className="open-account-btn-style">Open Company</button>
          </NavLink>
        )}
      </>
    ) : (
      <></>
    )}
  </NavItem>
);
export const SignUp = ({ userName, userType }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    window.location.reload();

    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {
      navigate('/');
      if (window.location.pathname === '/') {
        window.location.reload();
      }
    }
  };

  const dispatch = useAppDispatch();

  const customerDetails = useAppSelector(state => state.customer.entities);
  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const res = await dispatch(getCustomerDetails());

      } catch (error) {
        console.error("Failed to fetch customer details:", error);
      }
    };

    fetchCustomerDetails();
  }, [dispatch]);

  return (
    <NavItem className="right-text ms-3">
      {userName ? (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle tag={NavLink} className=" align-items-center custom-button" caret>
            {userType === 'ROLE_ADMIN' ? (
              <span>Admin</span>
            ) : (

              <span>{customerDetails?.firstName}&nbsp;{customerDetails?.lastName}</span>
            )}
          </DropdownToggle>
          <DropdownMenu right style={{ background: 'none' }}>
            <DropdownItem
              tag={Link}
              to="/logout"
              onClick={handleClick}


              className="client-portal-btn-style custom-dropdown-item text-center"
            >
              Log Out
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <>
          {!userName ? (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="align-items-center">
              <DropdownToggle caret className="custom-button">
                Log In
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag={Link} to="/signin">
                  Customer Log In
                </DropdownItem>
                <DropdownItem tag={Link} to="/admin-signin">
                  Admin Log In
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle tag={NavLink} className="align-items-center custom-button" caret>
                {userType === 'ROLE_ADMIN' ? (
                  <span>Admin</span>
                ) : (

                  <span>{customerDetails?.firstName}&nbsp;{customerDetails?.lastName}</span>
                )}
              </DropdownToggle>
              <DropdownMenu right style={{ background: 'none' }}>
                <DropdownItem
                  tag={Link}
                  to="/logout"
                  onClick={handleClick}

                  className="sign-out-btn-style custom-dropdown-item text-center"
                >
                  Log Out
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </>
      )}
    </NavItem>
  );
};

