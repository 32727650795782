
import React, { useEffect, useRef, useState } from 'react';
import './companySos.css';
import { Button, Col, Form, Row } from 'reactstrap';
import { useParams } from 'react-router';
import { getAdminAccounts } from '../admin-profile/admin.reduce';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useReactToPrint } from 'react-to-print';


const CompanySos = ({ companyDetails,
    sosPrimary_sign,
    setsosPrimarySign,
    // name,
    // lastName,
    initialRequirements,
    onBehalfOf,
    setOnBehalfOf,
    authorisePosition,
    setAuthorisePosition,
    handleOnBehalfOf,
    handleAuthorisePosition,
}) => {

    const dispatch = useAppDispatch();
    const currentDate = new Date().toLocaleDateString('en-GB');
    const { accountId } = useParams();
    const accountsEntity = useAppSelector(state => state.accounts.entity);

    const [applicationData, setApplicationData] = useState<any>({});
    const [newData, setNewData] = useState<any>({});
    useEffect(() => {
        if (accountId) {
            dispatch(getAdminAccounts({ accountId }))
                .then((response: any) => {
                    if (response.payload.data) {
                        setApplicationData(response.payload.data.account_signature_response);
                        setNewData(response.payload.data)

                    }
                })
                .catch((error) => {
                });
                
        }
    }, [accountId]);




    const pageStyle = `

  @media print {
    body {
      font-size: 13px;
    }

    .no-print {
      display: none;
    }

    .new-form-bold-text,
    .new-form-italic-text,
    .new-form-underline-text {
      font-size: 13px;
      font-weight: bold;
      color: black;
    }

    .company-invest-suit-text {
  color: black;
  font-family: 'Raleway', Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  font-weight: 15px;
  font-weight: 600;
}
.textfield-style {
  padding: 0.5rem 0.75rem;
}
.validated-textfield-style {
  padding: 0.2rem 0.75rem;
  border: 1px solid #f1eded;
  border-radius: 0.25rem;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  box-shadow: 0px 0.5px #c8c6c6;
}

.comment-box-style {
  padding: 0.5rem 0.75rem;
  border: 1px solid #f1eded;
  border-radius: 0.25rem;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  box-shadow: 0px 0.5px #c8c6c6;
}

.button-company-custom {
  border-radius: 6px;
  border: 1px solid #607980;
  color: black;
  background-color: #e1eff2;
  border-width: 2px;
  font-size: 13px;
  padding: 4px 13px;
  width: 50%;
}

.button-company-custom:hover {
  background-color: #607980;
  color: white;
}
.button-company-custom-submit {
  border-radius: 6px;
  border: 1px solid #607980;
  background-color: #607980;
  color: white;
  border-width: 2px;
  font-size: 13px;
  padding: 4px 13px;
  width: 50%;
}

.button-company-custom-submit:hover {
  background-color: #e1eff2;
  color: rgb(0, 0, 0);
}

.upload-button-new-style {
  word-break: break-all;
}

.table-new-style {
  overflow-x: scroll;
}
#unique-table {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#unique-table::-webkit-scrollbar {
  display: none;
}

.companysosdoc-text-fields {
  outline: none;
  border: none;
  padding-left: 0;
}


    .new-form-heading-text {
      font-weight: bold;
      font-size: 25px;
      color: black;
    }

    .new-form-light-text {
      font-weight: 300;
      font-size: 13px;
      color: black;
    }

    .new-form-very-light-text {
      font-weight: 300;
      font-size: 12px;
      color: black;
    }

    .new-form-italic-text {
      font-style: italic;
    }

    .bordered-table {
      width: 100%;
      border-collapse: collapse;
    }
    .bordered-table th,
    .bordered-table td {
      border: 1px solid black;
      padding: 8px;
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
    .bordered-table th {
      background-color: #f2f2f2;
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
    .new-form-table-row-one {
      background: #607980;
      color: black;
      font-weight: 300;]
      font-size: 13px;
    }
    .new-form-table-row-two {
      background-color: #d5d8da;
      color: black;
      font-weight: 300;
      font-size: 13px;
    }

    .default-bold-text-small {
  font-weight: bold;
  font-size: small;
}
.top-header-text-container {
  text-align: center;
}
.top-header-text-larger {
  font-weight: bold;
  font-size: x-large;
}
.top-header-text-large {
  font-weight: bold;
  font-size: large;
}
.top-header-text-small {
  font-weight: bold;
  font-size: small;
}
.top-form-text-input {
  border: none;
  background-color: rgb(255, 255, 255);
}

.invested-amount-form-text-input {
  border: none;
  background-color: rgb(255, 255, 255);
}

.page-one {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);
}
.page-two {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);
}
.page-three {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);
}
.page-four {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);
}
.page-five {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);
}
.page-six {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);
}
.page-seven {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);
}
.sign-container {
  border-bottom: 1px solid;
  width: 200px;
}
.warning-box-container {
  border: 2px solid;
}
table {
}

td {
  border: 1px solid #000000;
  background-color: #77777b;
  color: #ffffff;
  text-align: center;
}
th {
  border: 1px solid #000000;
  background-color: #c9eaff;
}

tr {
  line-height: 35px;
}

  }

`;

const customerDetails = useAppSelector(state => state.customer.entities);
const [name, setName] = useState(customerDetails?.firstName || '');
const [lastName, setLastName] = useState('');
useEffect(() => {
    if (customerDetails) {
      setName(customerDetails?.firstName || '');
      setLastName(customerDetails?.lastName || '');
    }
  }, [customerDetails]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Statement of Suitability',
        onBeforePrint: () => console.log('before printing...'),
        onAfterPrint: () => console.log('after printing...'),
        pageStyle,
        removeAfterPrint: true,
    });
    const componentRef = useRef();

    return (
        <div ref={componentRef}>
            <Row className='new-form-company-custom' >
                <Col md="12" className="  ">
                    <div className=" justify-content-center">
                        <Form className="">
                            <Row>
                                <div className='page-one p-3 ps-5 '>
                                    <div className='top-header-text-container'>
                                        <span className='top-header-text-larger'>Statement of Suitability</span>
                                    </div>
                                    <div className='top-header-text-container'>
                                        <span className='top-header-text-large'>Starlight Smart Reserve</span>
                                    </div>
                                    <div className='top-header-text-container pt-4'>
                                        <span className='default-bold-text-small'>Important Notice – Statement of Suitability</span>
                                    </div>
                                    <div><p><strong>This is an important document which sets out the reasons why the product(s) or service(s) offered or recommended is/are considered suitable, or the most suitable, for your particular needs, objectives and circumstances.</strong></p></div>
                                    <div>
                                        <div className='d-flex'>
                                            <div className='default-bold-text-small' style={{ width: '100px' }}>Prepared for:</div>
                                            <div className='default-bold-text-small'>{companyDetails?.company_name || newData?.company_details?.company_name || accountsEntity?.company_details?.company_name}</div>
                                        </div>
                                        <div className='d-flex'>
                                            <div className='default-bold-text-small' style={{ width: '100px' }}>Client:</div>
                                            <div className='default-bold-text-small'>{companyDetails?.company_name || newData?.company_details?.company_name || accountsEntity?.company_details?.company_name}</div>
                                        </div>
                                        <div className='d-flex'>
                                            <div className='default-bold-text-small' style={{ width: '100px' }}>Prepared By:</div>
                                            <div className='default-bold-text-small'>Starlight Wealth</div>
                                        </div>
                                        <div className='d-flex'>
                                            <div className='default-bold-text-small' style={{ width: '100px' }}>Date:  </div>
                                            <div className='default-bold-text-small'>{currentDate}</div>
                                        </div>
                                        </div>

                                    <div className='d-flex  mt-3'>
                                        <div className='default-bold-text-small'>Invested Amount : {initialRequirements?.USDReserve ? '$' : '€'}{initialRequirements?.investmentAmount || newData?.investmentAmount || accountsEntity?.investmentAmount}</div>

                                    </div>
                                    <div className='default-bold-text-small pt-2'>Introduction</div>
                                    <div className='d-flex'>
                                    <div className='d-flex'>
                                    <div className='C'>
                                                Dear&nbsp;
                                                {
                                                   newData.accountHolderName!=null
                                                    ? `${newData.accountHolderName} ${newData.accountHolderSurname}`
                                                    : `${name || ''} ${lastName || ''}`
                                                }
                                                </div>
                                                 </div>
                                    </div>
                                    <div>
                                        <div className='mt-3'>
                                            <p>
                                                Thank you for taking the time to complete a fact find and tell us about the company’s investment needs. Following an analysis of the company’s financial circumstances and based on the information you have provided,  we can now make an investment recommendation that we believe meets the company’s investment objectives and its specific needs and objectives with respect to the Investment Amount.
                                            </p>
                                        </div>
                                        <div>
                                            <div className='default-bold-text-small'>
                                                Our Services
                                            </div>
                                            <p>Starlight Wealth is not a tied agent with respect to Starlight Smart Reserve. We provide investment intermediary services on the basis of independent advice. This means that the firm does not accept or retain any third party payments (i.e. commissions) from product producers with respect to Starlight Smart Reserve. We may provide investment advice and may receive and transmit orders in respect of the following regulated instruments: listed shares and bonds and collective investments. </p>
                                        </div>
                                        <div>
                                            <div className='default-bold-text-small'>
                                                Needs and Objectives
                                            </div>
                                            You have confirmed that:
                                            <ul>
                                                <li>You have a lump sum available to invest</li>
                                                <li>The company’s investment objective is to preserve capital and generate a return that reflects the European Central Bank (ECB) Deposit Rate</li>
                                                <li>You wish to open a corporate account</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <div className='default-bold-text-small'>
                                                Company Status
                                            </div>
                                            You have confirmed that:
                                            <ul>
                                                <li>The company is resident in Ireland</li>
                                                <li>The company  Will be the Beneficial Owner of the Account</li>
                                                <li>The company Is Tax Resident In Ireland</li>
                                                <li>The company has a minimum of 3 months of operating expenses covered from other funds</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <div className='default-bold-text-small'>
                                                Suitability and Recommendations          </div>
                                            <p>Based upon the information you have provided to us, we recommend that you invest your specified Investment Amount in the<span className='default-bold-text-small'>Starlight Smart Reserve (EUR)</span>  model portfolio of Short-Term Money Market Funds via a <span className='default-bold-text-small'>Starlight Smart Account (“Starlight Smart Reserve”)</span> </p>
                                            <p>The <span className='default-bold-text-small'>Starlight Smart Reserve Portfolio (EUR) </span>) comprises a select model portfolio of AAA rated and Sovereign Short Term Money Market Funds (MMFs), each sponsored and managed by established blue-chip international financial institutions. The Starlight Smart Reserve Portfolio (EUR) is a demand deposit alternative which is designed to generate an income yield that tracks the Euro Short Term Rate (ESTR), the overnight deposit rate of Eurozone banks. The model portfolio is 100% allocated to Short Term Money Market Funds.</p>
                                            <p>We recommend that your investment horizon with respect to the Starlight Smart Reserve Portfolio (EUR) be no less than one week.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='page-two mt-5 p-3 ps-5'>
                                    <div className='default-bold-text-small'>
                                        <p>Given your needs and objectives with respect to the Investment Amount, we recommend that you invest in accordance with the Starlight Smart Account Allocation Mandate set out in Schedule A.
                                        </p>
                                    </div>
                                    <div>
                                        <p>It is in your best interests that you review the investment portfolio comprising your <span className='default-bold-text-small'> Starlight Smart Account</span> on a regular basis. As your circumstances change, your needs may change. Our recommendation is that your investment allocations be reviewed on an annual basis.  Should you wish to change your investment allocation at any time (for example, to invest in the equity markets) this may be done by contacting us via the <span className='default-bold-text-small'>‘Client Portal’</span> button on the <span className='default-bold-text-small'>Starlight website.</span> We may require you to complete a further suitability assessment and a risk questionnaire at this stage.
                                        </p>
                                    </div>
                                    <div>
                                        <div className='default-bold-text-small'>
                                            Product features and options
                                        </div>
                                        <p>The <span className='default-bold-text-small'>Starlight Smart Account</span> is a self-directed advisory investment account that we arrange and open in your name with our MIFID Executing Broker, Interactive Brokers Ireland Limited. The Starlight Smart Account provides 
                                            access to our range of proprietary Starlight Model Portfolios on the MIFID Executing Broker dealing platform, including <span className='default-bold-text-small'>Starlight Smart Reserve (EUR)</span>, as well as other listed shares, bonds, and exchange traded funds, 
                                            subject in all cases to our Terms of Business and, except for <span className='default-bold-text-small'>Starlight Smart Reserve (EUR)</span>, an additional Statement of Suitabillity.  </p>
                                            <p>The <span className='default-bold-text-small'>Starlight Smart Account</span> is a ‘cash only’ investment account that does not allow you to borrow money to supplement or leverage your own funds to make investments in your account. </p>
                                            <p>The <span className='default-bold-text-small'>Starlight Smart Account</span> is subject to a straightforward transparent fee as set out in the Starlight Remuneration and Fee Schedule. Our fee is charged on the value of the assets in your 
                                            account and is accrued daily and charged monthly in arrears. This fee will be automatically debited on the first Business Day of each month. We do not charge any upfront fees, contribution fees, account set-up fees, fees to administer your account, 
                                            fees to execute trade orders on your behalf, or if you ever decide to leave, exit fees. </p>
                                            <p>There is no minimum mandatory investment period where your investments are locked in. You can instruct us to sell your investments at any time. You can start or stop regular contributions or make further ad hoc lump sum contributions at any time. 
                                                You have the option to switch investments at any time subject to our Terms of Business. You will be able to see the value of your investment portfolio via the <span className='default-bold-text-small'>‘Client Portal’</span> button on the <span className='default-bold-text-small'>Starlight website.</span> </p>
                                    </div>
                                    {/* <div>
                                        <p>
                                            The <span className='default-bold-text-small'>Starlight Smart Account</span> is subject to a straightforward transparent fee as set out in the Starlight Remuneration and Fee Schedule. Our fee is charged on the value of the assets in your account and is accrued daily and charged monthly in arrears. This fee will be automatically debited on the first Business Day of each month.
                                        </p>
                                        <p>
                                            There is no minimum mandatory investment period where your investments are locked in. You can instruct us to sell your investments at any time. You can start or stop regular contributions or make further ad hoc lump sum contributions at any time. You have the option to switch investments at any time free of switching charges, but subject to transaction timings and costs.
                                        </p>
                                        <p>You will be able to see the value of your investment portfolio via the <span className='default-bold-text-small'>‘Client Portal’</span>  button on the <span className='default-bold-text-small'> Starlight website.</span></p>
                                    </div> */}
                                </div>
                                <div className='page-three mt-5 p-3 ps-5'>
                                    <div className='default-bold-text-small'>
                                        Starlight Smart Reserve - Key Features and Risks
                                    </div>
                                    <div>
                                        <p>The Starlight Smart Reserve Portfolio (EUR) provides three key benefits:</p>
                                        <ol>
                                            <li>
                                                <span className='default-bold-text-small'>Diversification :</span> Your Investment Amount is spread across approximately 200 deposits and short-term investments held by the money market funds comprising the Starlight Smart Reserve Portfolio (EUR), rather than just being held in a single bank. In the unlikely event that one of these proportionately small deposits or investments were to default, as your Investment Amount is so broadly diversified, any loss should be small and in most cases should be more than offset by the income you earn over a typical investment horizon. In contrast, if you kept your Investment Amount on deposit with a single bank, then if that bank were to default, you may lose all of your money if the State guarantee could not pay-out or the State guarantee did not apply to some or all of your deposit.
                                            </li>
                                            <li>
                                                <span className='default-bold-text-small'>Pricing Power – Institutional Return on Cash :</span>As the money market funds comprising the Starlight Smart Reserve Portfolio (EUR) pool your Investment Amount with other investors’ money, these money market funds are able to get a much higher rate of interest than you would be able to get as a normal bank customer. This higher rate of interest is shared with you.
                                            </li>
                                            <li>
                                                <span className='default-bold-text-small'>Liquidity - Access to Funds:</span>You can withdraw some or all of your Investment Amount at any time. There is no fixed-term and no exit penalties. It typically takes 2 to 5 business days to access your funds, subject to and in accordance with our Terms of Business.
                                            </li>
                                        </ol>
                                    </div>
                                    <div>
                                        <span className='default-bold-text-small'><i>The Ireland Deposit Guarantee Scheme Does Not Apply to Your Investment</i></span>. The AAA rated and Sovereign EUR money market funds comprising the Starlight Smart Reserve Portfolio (EUR) are not guaranteed and do not benefit from the Irish State’s Deposit Guarantee Scheme.
                                    </div>
                                    <div>
                                        <p>An investment in a money market fund is different from an investment in deposits; the principal invested in a money market fund is capable of fluctuation and the risk of loss of the principal is to be borne by the investor. Under EU law, Short-Term Money Market Funds, including the money market funds comprising  the Starlight Smart Reserve Portfolio (EUR), are required to maintain the highest levels of overnight liquidity under the relevant regulations, which is explicitly designed to significantly mitigate Credit Risk, Market Risk and Interest Rate Risk.</p>
                                        <p>
                                            Credit Risk: If a bank deposit or investment in one of the money market funds comprising the Starlight Smart Reserve Portfolio (EUR) were to default and such deposit or investment did not benefit from a full state guarantee or was not otherwise remedied, then the money market fund would receive back less than the full amount of the deposit or capital that it has invested. This would cause the money market fund redemption price to fall which would result in the income yield payable on the Starlight Smart Reserve Portfolio (EUR) being reduced. If the default was significantly large enough, this could result in you suffering a loss of capital.
                                        </p>
                                        <p>
                                            Market Risk: If the value of the bank deposit or investments in one or more of the money market funds comprising the Starlight Smart Reserve Portfolio (EUR) was to fall then the fall in value would be reflected in the money market fund redemption price.
                                        </p>
                                        <p>
                                            Interest Rate Risk: If interest rates rise very quickly then the redemption price of money market funds comprising the Starlight Smart Reserve Portfolio (EUR) may fall temporarily. As such money market funds are Short Term Money Market Funds, this fall should be quickly offset by increased income from the reinvestment of redeeming assets in the money market funds into new assets earning a higher interest rate.
                                        </p>
                                        <p>
                                            Low and Negative Interest Rates: If interest rates fall so low or become negative as to be less than the charges applied to the money market funds comprising the Starlight Smart Reserve Portfolio (EUR)  and the Starlight Smart Account, such charges would be discharged from your capital and accumulated interest.
                                        </p>
                                    </div>
                                    <div>
                                        <p className='default-bold-text-small'>Product Guarantees and Associated Risks</p>
                                        <p className='default-bold-text-small'><u>The investments are not guaranteed. </u>If you invest in this product you may lose some or all of the money you invest. </p>
                                        <p className='default-bold-text-small'>Starlight Wealth does not accept or hold any client monies.  Your Investment Amount is invested directly in the AAA and Sovereign EUR Money Market Funds comprising the Starlight Smart Reserve Portfolio (EUR). Funds and investments in your Starlight Smart Account are ‘client assets’ and are fully segregated from the assets of the MIFID Executing Broker in accordance with Central Bank of Ireland regulatory rules.</p>
                                        <p>Future Wealth Limited, trading as Starlight Wealth, is a member of both the Investor Compensation Company (ICCL) and Brokers Ireland compensation schemes. </p>
                                        <span className='default-bold-text-small'>Taxation</span>
                                        <p>Your tax and accounting position is personal to you and may affect the ultimate return on your investment. We do not advise on the tax or accounting consequences of investments. Please note that the basis and rate of taxation as applicable to investments in the Starlight Smart Account may change without notice. Please see Appendix A for an outline of our understanding of the general tax position as it relates to the investments.</p>
                                    </div>
                                </div>
                                <div className='page-four mt-5 p-3 ps-5'>
                                    <div>
                                        <span className='default-bold-text-small'>
                                            Next Steps
                                        </span>
                                        <p> Please review this document and the associated Starlight Smart Reserve (EUR) model portfolio summary document (MPSD) carefully. If you agree with the recommendation and wish to proceed, please accept and sign this document. By signing this document, you confirm that:</p>

                                        <ol>
                                            <li>
                                                You have read this Statement of Suitability and that the information about your circumstances, needs and objectives and financial situation is accurate; and
                                            </li>
                                            <li>You want your funds to be allocated in accordance with your Starlight Smart Account Allocation Mandate and the Terms of Business.
                                            </li>
                                        </ol>
                                        <div className='mt-3'>This document supersedes all prior oral communication and prior writings with respect to its subject matter.</div>

                                        <div className='mt-2 sign-container'>
                                            <input className='companysosdoc-text-fields'
                                                value={companyDetails?.company_name || newData?.company_details?.company_name || accountsEntity?.company_details?.company_name}
                                            // onChange={handleOnBehalfOf}
                                            />
                                        </div>
                                        <div className='default-bold-text-small'>
                                            For and on behalf of:
                                        </div>
                                        <div className='mt-2'>
                                            <div className='d-flex align-item-center '>
                                                Name of Authorised Signatory:
                                                &nbsp;<input className="signature-container-inside-box-2-input mb-2" value={sosPrimary_sign || applicationData?.statement_of_suitability?.primary_sign}
                                                    onChange={e => setsosPrimarySign(e.target.value)} />
                                            </div>
                                            <div className='d-flex mt-2'>
                                                Position:
                                                &nbsp;<input className='signature-container-inside-box-2-input'
                                                    value={authorisePosition || newData?.authorisePosition}
                                                    onChange={handleAuthorisePosition}
                                                />
                                            </div>
                                            <div className='d-flex mt-3'>
                                                Date:&nbsp;{currentDate}
                                                {/* <DatePickerComponent
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="MM/dd/yyyy"
                                className="form-control"
                                styles={customStyles}
                                popperClassName="datepicker-popper"
                            /> */}

                                            </div>

                                        </div>
                                    </div>

                                    <div className='warning-box-container pt-3 ps-5 mt-5'>
                                        <p className='default-bold-text-small'>Warning: If you invest in this product you may lose some or all of the money you invest. </p>
                                        <p className='default-bold-text-small'>Warning: Past Performance is not a reliable guide to future performance. </p>
                                        <p className='default-bold-text-small'>Warning: The value of your investment may go down as well as up. </p>
                                        <p className='default-bold-text-small'>Warning: The income you get from this investment may go down as well as up</p>
                                        <p className='default-bold-text-small'>Warning: These figures are estimates only. They are not a reliable guide to future performance of this investment.</p>
                                        <p className='default-bold-text-small'>Warning: This product may be affected by changes in currency exchange rates</p>
                                    </div>
                                </div>
                                <div className='page-five mt-5 p-3 ps-5'>
                                    <div className='top-header-text-container'>
                                        <span className='default-bold-text-small'>Schedule A</span>
                                    </div>
                                    <div className='top-header-text-container'>
                                        <span className='default-bold-text-small '>Starlight Smart Account Allocation Mandate</span>
                                    </div>
                                    <div className='mt-5'>
                                        <table className="table table-striped custom-table-td">
                                            <tr className='new-form-table-row-one'>
                                                <th className="fs-6">Model Portfolio/Cash</th>
                                                <th className="fs-6">Description</th>
                                                <th className="fs-6">Reference Document</th>
                                                <th className="fs-6">Risk Rating</th>
                                                <th className="fs-6">Allocation %</th>
                                            </tr>
                                            <tr className='new-form-table-row-two'>
                                                <td>Starlight Smart Reserve Portfolio</td>
                                                <td>Money Market Fund Model Portfolio</td>
                                                <td>Smart Reserve Portfolio  (EUR) (Series 1) MPSD</td>
                                                <td>1</td>
                                                <td>99%</td>
                                            </tr>
                                            <tr className='className="new-form-table-row-two'>
                                                <td>Cash</td>
                                                <td>Cash Balance</td>
                                                <td>N/A</td>
                                                <td>1</td>
                                                <td>1%</td>
                                            </tr>
                                            <tr className='new-form-table-row-one'>
                                                <td>Overall</td>
                                                <td>Starlight Smart Account</td>
                                                <td></td>
                                                <td>1</td>
                                                <td>100%</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className='mt-5'>
                                        <p>
                                            In accordance with the Terms of Business, I hereby instruct Future Wealth Limited, trading as Starlight Wealth, to transmit trade orders to the MIFID Executing Broker and, as applicable, utilise the available balance of my Starlight Smart Account allocated to each model portfolio (as specified in this Starlight Smart Allocation Mandate), to buy or sell an amount of each of the individual securities comprising each model portfolio in proportion to the weightings of the model portfolio set out in the relevant model portfolio summary document (MPSD).
                                        </p>
                                        <p>
                                            Capitalised terms used herein have the meaning given to such terms in the Terms of Business.
                                        </p>
                                    </div>
                                </div>
                                <div className='page-six mt-5 p-3 ps-5'>
                                    <div className='top-header-text-container'>
                                        <span className='default-bold-text-small'>Appendix A</span>
                                    </div>
                                    <div className='top-header-text-container'>
                                        <span className='default-bold-text-small '>Taxation</span>
                                    </div>
                                    <div className='mt-3'>
                                        <span className='default-bold-text-small '>Starlight Smart Reserve Portfolio (EUR); Starlight Smart Reserve Portfolio (USD)</span>
                                    </div>
                                    <div>
                                        <p>
                                            Clients’ investments in these model portfolios are made up of whole and fractional holdings of Money Market Funds (MMF). By investing in the relevant model portfolio(s), clients own the individual whole and fractional shares of the MMFs comprising the model portfolio.
                                        </p>
                                    </div>
                                    <div>
                                        <span className='default-bold-text-small'>Companies</span>
                                    </div>
                                    <div>
                                        <p>
                                            It is our understanding that the taxation of returns/accumulated interest in the money market funds comprising these model portfolio(s) may, subject to the provision of the relevant declaration as required by the relevant tax legislation, be taxed at 25%. If this declaration is not made or cannot be made in accordance with the legislation, then our understanding is that a tax rate of 41% and not 25% would currently apply to any profits or gains.
                                        </p>
                                        <p>
                                            In either case it is our understanding that these investments are not subject to capital gains tax (CGT) or deposit interest retention tax (DIRT). It is also our understanding that returns/accumulated interest will be rolled up on a gross basis allowing for compounding within the funds but subject to tax only following redemption or distribution from the money market funds comprising the model portfolio(s).
                                        </p>
                                        <div>
                                            <p className='default-bold-text-small'>
                                                Please note that the views or opinions concerning taxation accounting or legal matters, whether in writing or orally, are intended to be expressions of opinion and are not to be construed as investment, tax, accounting, or legal advice.
                                            </p>
                                            <p className='default-bold-text-small'>
                                                Your tax and accounting position is personal to you and may affect the ultimate return on your investment. We do not advise on the tax or accounting consequences of investments.
                                            </p>
                                            <p className='default-bold-text-small'>
                                                For tax advice, you are advised to contact an independent tax adviser, for accounting advice, your company accountant and for legal advice an appropriately licensed law firm.
                                            </p>
                                            <p className='default-bold-text-small'>
                                                Please note that the basis and rate of taxation as applicable to investments in the Starlight Smart Account may change without notice.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <Col md="12" className="pt-3 text-end no-print">
                                    <Button onClick={() => handlePrint({ content: () => document.body })}>Print</Button>
                                </Col>
                            </Row >

                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default CompanySos;


