// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control {
  border-radius: 4px;
  border: "2px dashed #ccc";
  font-size: 16px;
  color: #263438;
}

.validated-field2 {
  color: #9fcdda;
  font-weight: 400;
  font-family: "Montserrat";
}

.signin-btn-style {
  background-color: #607980;
  border-color: #b6c1cd;
  border-width: 2px;
  font-size: 13px;
  color: white;
  font-family: "Montserrat";
}

.signin-btn-style:hover {
  background-color: #e1eff2;
  color: rgb(0, 0, 0);
  font-family: "Montserrat";
}

.terms-and-conditions {
  font-family: "Raleway", Verdana, Geneva, Tahoma, sans-serif;
  color: #9fcdda;
}

.new-login-user-id {
  font-family: "Raleway", Verdana, Geneva, Tahoma, sans-serif;
  color: #38606b;
}

.reg-close {
  font-size: x-small;
  background-color: transparent;
  border: none;
}

.reg-close:hover {
  color: red;
  background-color: transparent;
}

.validated-field2 label::before {
  content: "*";
  color: red;
  margin-right: 4px;
  font-family: "Montserrat";
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/login/login.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,cAAA;AACF;;AAEA;EACE,cAAA;EACA,gBAAA;EACA,yBAAA;AACF;;AAEA;EACE,yBAAA;EACA,qBAAA;EACA,iBAAA;EACA,eAAA;EACA,YAAA;EACA,yBAAA;AACF;;AACA;EACE,yBAAA;EACA,mBAAA;EACA,yBAAA;AAEF;;AACA;EACE,2DAAA;EACA,cAAA;AAEF;;AACA;EACE,2DAAA;EACA,cAAA;AAEF;;AACA;EACE,kBAAA;EACA,6BAAA;EACA,YAAA;AAEF;;AACA;EACE,UAAA;EACA,6BAAA;AAEF;;AAAA;EACE,YAAA;EACA,UAAA;EACA,iBAAA;EACA,yBAAA;AAGF","sourcesContent":[".form-control {\n  border-radius: 4px;\n  border: '2px dashed #ccc';\n  font-size: 16px;\n  color: #263438;\n}\n \n.validated-field2 {\n  color: #9fcdda;\n  font-weight: 400;\n  font-family: 'Montserrat';\n}\n \n.signin-btn-style {\n  background-color: #607980;\n  border-color: #b6c1cd;\n  border-width: 2px;\n  font-size: 13px;\n  color: white;\n  font-family: 'Montserrat';\n}\n.signin-btn-style:hover {\n  background-color: #e1eff2;\n  color: rgb(0, 0, 0);\n  font-family: 'Montserrat';\n}\n \n.terms-and-conditions {\n  font-family: 'Raleway', Verdana, Geneva, Tahoma, sans-serif;\n  color: #9fcdda;\n}\n \n.new-login-user-id {\n  font-family: 'Raleway', Verdana, Geneva, Tahoma, sans-serif;\n  color: #38606b;\n}\n \n.reg-close {\n  font-size: x-small;\n  background-color: transparent;\n  border: none;\n}\n \n.reg-close:hover {\n  color: red;\n  background-color: transparent;\n}\n.validated-field2 label::before {\n  content: '*';\n  color: red;\n  margin-right: 4px;\n  font-family: 'Montserrat';\n}\n "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
