

import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
 
 
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { authenticate, login } from 'app/shared/reducers/authentication';
import LoginModal from './login-modal';
import SuccessModal from './successmodal';
import ErrorModal from './errormodal';
import CommonToast from '../components/CommonToast';
import { Button } from 'reactstrap';
import { AxiosError, AxiosResponse } from 'axios';

export const Login = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const account = useAppSelector(state => state.authentication.account);
 
  const isAccount = useAppSelector(state => state);
  const loginError = useAppSelector(state => state.authentication.loginError);
  const showModalLogin = useAppSelector(state => state.authentication.showModalLogin);
  const [showModal, setShowModal] = useState(showModalLogin);
  const navigate = useNavigate();
  const pageLocation = useLocation();
  const [userId, setUserId] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setShowModal(true);
  }, []);

  useEffect(() => {
    if (userId !== '') {
      setShowSuccessModal(true);
    }
  }, [userId]);

  const handleLogin = async (username, password, firstname, lastname, email, dob, phone) => {
    try {
      const role: string = 'ROLE_USER';
      const result: any = await dispatch(authenticate({ role, username: email, password, firstname, lastname, email, dob, phone }));
  
      if (result.payload instanceof AxiosError) {
    
        const payloadError = result.payload.response.data.detail;
        let customMessage = "An error occurred"; // Default error message

        if (payloadError.startsWith("409 Conflict: \"{\"errorMessage\":\"")) {
          customMessage = payloadError
            .replace("409 Conflict: \"{\"errorMessage\":\"", "")
            .slice(0, -3); // Remove the last two characters: "}
        }

        setErrorMessage(customMessage);
        setShowErrorModal(true);
      }
      else {
        const response = result.payload as AxiosResponse;

        if (response.data !== undefined) {
          setUserId(response?.data?.username);
        }
      }
    } catch (error) {
      setErrorMessage('User already registered');
      setShowErrorModal(true);
    }
  };
 
  const handleClose = () => {
    setShowModal(false);
    navigate('/');
  };
 
  const { from } = pageLocation.state || { from: { pathname: '/', search: pageLocation.search } };
  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  return (
    <>
      <LoginModal showModal={showModal} handleLogin={(username, password, firstname, lastname, email, dob, phone) => { handleLogin(username, password, firstname, lastname, email, dob, phone) }} handleClose={handleClose} loginError={loginError} />


      <CommonToast
        visible={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        // title={'Test'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
      >
        <div className="custom-content">
 
          <div className="font-weight-bold custom-modal-style mb-3" >
            Thank you for registering with Starlight Wealth
            <br /> Your Username:&nbsp;<span className='new-login-user-id'>{userId}</span>
          </div>
          <Button color="primary mt-1" onClick={() => {
            setShowSuccessModal(false);
            navigate('/signin');
          }}>
            Next
          </Button>
        </div>
      </CommonToast>
 
      <CommonToast
        visible={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        // title={'Test'}
        description={errorMessage}
        needCloseButton={true}
        type="error"
        isAutoClose={true}
      />
 
 
    </>
  );
};
 
export default Login;
 
 