import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { forwardRef, useImperativeHandle } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { updateEntity, updateEntityProperty } from './accounts.reducer';

interface PepProps {
  onFormComplete: (completed: boolean) => void;
}

export interface PepRef {
  syncPepWithServer: () => Promise<any>;
}

const Pep: React.ForwardRefRenderFunction<PepRef, PepProps> = ({ onFormComplete }: PepProps, ref) => {
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
 
  const dispatch = useAppDispatch();

  useImperativeHandle(ref, () => ({
    syncPepWithServer
  }));

  const syncPepWithServer = (): Promise<any>=> {
    return dispatch(updateEntity({...mainAccountsEntity, last_level: 7}));
  };

  const handleAreYouPepChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name } = e.target;
    dispatch(updateEntityProperty({
      isPep: name === "ARE_YOU_A_PEP_YES"
    }));
  };

  const handleFamilyMemberPepChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name } = e.target;
    const isFamilyPep = name === "FAMILY_MEMBER_PEP_YES";
    dispatch(updateEntityProperty({
      isFamilyPep
    }));
  };

  const handleFamilyMemberPepDetailsChange = e => {
    const { value } = e.target;
    dispatch(updateEntityProperty({ familymemberpepdetails: value }));
  };

  return (
    <div>
      <Row>
        <Col md="12" className="form-firstrow-account-head-custom">
          {`Politically Exposed Person (PEP)`}
        </Col>
        <Col md="12" className="pt-3">
          A “PEP” is defined as a person who is or has been entrusted with a prominent public function in Ireland or abroad, including:
          politicians, senior executives of government bodies, or immediate family members of the above.
        </Col>
        <Col md="12" className="pt-3">
          We are required by law to identify if any of our clients are PEPs and, if so, to apply certain checks and controls before opening
          an account for them or providing any services.
        </Col>
        {/* <Col md="12" className="pt-3">
          For joint applications, please answer for both parties
        </Col> */}
        <Col md="12" className="pt-3 label-account-class">
          Are You a PEP?
        </Col>
        <Col md="12 pt-1 d-flex">
          <FormGroup check>
            <Label check className="label-account-class">
              <Input
                type="checkbox"
                name="ARE_YOU_A_PEP_YES"
                id="ARE_YOU_A_PEP_YES"
                className="input-account-custom-bgnone"
                checked={mainAccountsEntity?.isPep === true}
                onChange={handleAreYouPepChange}
              />{' '}
              Yes
            </Label>
          </FormGroup>
          <FormGroup check className='ms-2'>
            <Label check className="label-account-class">
              <Input
                type="checkbox"
                name="ARE_YOU_A_PEP_NO"
                id="ARE_YOU_A_PEP_NO"
                className="input-account-custom-bgnone"
                checked={mainAccountsEntity?.isPep === false}
                onChange={handleAreYouPepChange}
              />{' '}
              No
            </Label>
          </FormGroup>
        </Col>
        <Col md="12" className="pt-3 label-account-class">
          Is a Family Member a PEP?
          <div className="account-sub-span-custom">
            Have you or a member of your immediate family ever worked for a government body, government agency, or government company?
          </div>
        </Col>
        <Col md="12 d-flex">
          <FormGroup check>
            <Label check className="label-account-class">
              <Input
                type="checkbox"
                name="FAMILY_MEMBER_PEP_YES"
                id="FAMILY_MEMBER_PEP_YES"
                className="input-account-custom-bgnone"
                checked={mainAccountsEntity?.isFamilyPep === true}
                onChange={handleFamilyMemberPepChange}
              />{' '}
              Yes
            </Label>
          </FormGroup>
          <FormGroup check className='ms-2'>
            <Label check className="label-account-class">
              <Input
                type="checkbox"
                name="FAMILY_MEMBER_PEP_NO"
                id="FAMILY_MEMBER_PEP_NO"
                className="input-account-custom-bgnone"
                checked={mainAccountsEntity?.isFamilyPep === false}
                onChange={handleFamilyMemberPepChange}
              />{' '}
              No
            </Label>
            <br />

          </FormGroup>
        </Col>


        {/* Conditional rendering of the details section */}
        {(mainAccountsEntity.isPep || mainAccountsEntity.isFamilyPep) && (
          <Col md="12">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>

                Please Provide Details

                <br />
                <span className="account-sub-span-custom">
                  {`If you have answered "Yes" to either of the above questions, please provide details`}
                </span>
              </Label>
              <Input
                type="text"
                name="familymemberpepdetails"
                id="familymemberpepdetails"
                value={mainAccountsEntity.familymemberpepdetails}
                className="textfield-style"
                onChange={handleFamilyMemberPepDetailsChange}
              />
            </FormGroup>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default forwardRef(Pep);
